




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
import { User } from '@/modules/settings/types/user.type';

@Component({
  name: 'UpdatePassword',
  components: { Modal }
})
export default class UpdatePassword extends Vue {
  @Prop() user!: User;

  @Prop({ default: false }) showNotification!: boolean;

  public passwordRules = [
    (value: string) =>
      (!!value && value.length > 7) || 'Un minimum de 8 caractères'
  ];

  public showCurrentPassword = false;
  public showNewPassword = false;
  public showConfirmNewPassword = false;
  public currentPassword = '';
  public newPassword = '';
  public confirmNewPassword = '';

  public onSave(): void {
    const { currentPassword, newPassword } = this;
    this.$emit('save', { currentPassword, newPassword });
    this.currentPassword = '';
    this.newPassword = '';
    this.confirmNewPassword = '';
  }

  public onClose(): void {
    this.$emit('close');
    this.currentPassword = '';
    this.newPassword = '';
    this.confirmNewPassword = '';
  }
}
