







































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Role as RoleT } from '../types/role.type';
import NewRole from './../components/role/new-role.vue';
import Role from './role/role.vue';

const roles = namespace('roles');
const application = namespace('application');

@Component({
  name: 'Roles',
  components: { Header, NewRole, Role }
})
export default class Roles extends Vue {
  @roles.State
  public roles!: RoleT[];

  public headers = [
    {
      text: 'Fullname',
      value: 'fullName'
    },
    { text: 'Description', value: 'description' },
    { text: '', value: 'actions' }
  ];

  public drawer = false;

  public displayRoles: RoleT[] = [];

  public search = '';

  private async created(): Promise<void> {
    this.setTitle('Roles Settings');
    await this.getRolesFromRemote();
    this.displayRoles = this.roles;
  }

  public onRole(role: RoleT): void {
    this.$emit('role', role);
    this.setRole(role);
    this.drawer = true;
  }

  private openDrawer(): void {
    this.drawer = true;
  }

  public handleRole(role: RoleT) {
    this.setRole(role);
    this.openDrawer();
  }

  public searchByName(args: string): void {
    this.search = args;
    this.displayRoles = this.roles.filter(role => {
      return (
        role != null &&
        ((role.fullName &&
          typeof args === 'string' &&
          role.fullName
            .toLocaleUpperCase()
            .indexOf(args.toLocaleUpperCase()) !== -1) ||
          (role.description &&
            typeof args === 'string' &&
            role.description
              .toLocaleUpperCase()
              .indexOf(args.toLocaleUpperCase()) !== -1))
      );
    });
  }

  @roles.Mutation
  private setRole!: (role: RoleT) => void;

  @roles.Action
  public getRolesFromRemote!: () => Promise<void>;

  @application.Mutation
  setTitle!: (title: string) => void;
}
