



































































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import CreateTimeOff from '../types/create-time-off.type';
import Label from '@/components/label.vue';
import SelectPeriod from '@/components/select-period.vue';

const timeOff = namespace('timeOff');

@Component({
  name: 'RequestTimeOff',
  components: { Header, Modal, Label, SelectPeriod }
})
export default class RequestTimeOff extends Vue {
  public firstDate = null;
  public lastDate = null;

  public showNotification = false;
  public dialog = false;
  public loading = false;

  public types = [
    'Vacation',
    'Sick Leave',
    'Unpaid Leave',
    'Abroad Mission',
    'Authorized Leave',
    'Local Mision',
    'Marriage',
    'Parent Death',
    'Paternity Leave',
    'Circumcision',
    'Training'
  ];

  @timeOff.State
  private timeOffBalance!: number;

  @timeOff.State
  newTimeOff!: CreateTimeOff;

  private created(): void {
    this.myTimeOffBalanceFromRemote();
  }

  public closeDialog() {
    this.dialog = false;
    this.showNotification = false;
    this.loading = false;
  }

  public async save() {
    this.loading = true;
    const result = await this.createTimeOff();
    if (result) {
      this.showNotification = true;
    }
    this.loading = false;
  }

  public setStartDate(startDate: Date) {
    this.setNewTimeOff({ ...this.newTimeOff, startDate });
  }

  public setEndDate(endDate: Date) {
    this.setNewTimeOff({ ...this.newTimeOff, endDate });
  }

  @timeOff.Mutation
  private setNewTimeOff!: (newTimeOff: CreateTimeOff) => void;

  @timeOff.Action
  private createTimeOff!: () => boolean;

  @timeOff.Action
  private myTimeOffBalanceFromRemote!: () => void;
}
