



















import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Team } from '../../types/team.type';

const settings = namespace('settings');

@Component({
  name: 'UserTeams',
  components: { Header }
})
export default class UserTeams extends Vue {
  @Prop({ default: false }) public onlyDataTable!: boolean;

  @Prop({ default: 'Teams' }) public title!: string;

  @Prop() public teams!: Team[];

  public headers = [
    {
      text: 'Fullname',
      value: 'fullName'
    },
    { text: 'Description', value: 'description' }
  ];

  public onTeam(team: Team) {
    this.$emit('team', team);
  }
}
