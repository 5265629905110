









































































































































































































































































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Role as RoleType } from '../../types/role.type';
import Label from '@/components/label.vue';
import LabelAndValue from '@/components/label-and-value.vue';
import Value from '@/components/value.vue';
import { UpdateRole } from '../../types/update-role.type';
import UpdateRoleDescription from './update-role-description.vue';
import UpdateRoleFullName from './update-role-fullname.vue';
import UpdateServices from './update-services.vue';
import DeleteRole from './delete-role.vue';
import { Service } from '../../types/service.type';

const roles = namespace('roles');

@Component({
  name: 'Role',
  components: {
    DeleteRole,
    Header,
    Label,
    LabelAndValue,
    Value,
    UpdateRoleDescription,
    UpdateRoleFullName,
    UpdateServices
  }
})
export default class Role extends Vue {
  @roles.State
  public role!: RoleType;

  public showNotification = false;

  public log(evt: unknown): void {
    console.log(evt);
  }
  public close(): void {
    this.showNotification = false;
  }

  public async updateServices(services: Service[]) {
    const isUpdated = await this.updateRole({ _id: this.role._id, services });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public updateIsPaydocs(isPaydocs: boolean) {
    this.updateRole({ _id: this.role._id, isPaydocs });
  }

  public updateIsPaydocsManager(isPaydocsManager: boolean) {
    this.updateRole({ _id: this.role._id, isPaydocsManager });
  }

  public updateIsTimeOff(isTimeOff: boolean) {
    this.updateRole({ _id: this.role._id, isTimeOff });
  }

  public updateIsTimeOffManager(isTimeOffManager: boolean) {
    this.updateRole({ _id: this.role._id, isTimeOffManager });
  }

  public updateIsPeople(isPeople: boolean) {
    this.updateRole({ _id: this.role._id, isPeople });
  }

  public updateIsRequests(isRequests: boolean) {
    this.updateRole({ _id: this.role._id, isRequests });
  }

  public updateIsRequestsManager(isRequestsManager: boolean) {
    this.updateRole({ _id: this.role._id, isRequestsManager });
  }

  public updateIsSettings(isSettings: boolean) {
    this.updateRole({ _id: this.role._id, isSettings });
  }

  public updateIsPayroll(isPayroll: boolean) {
    this.updateRole({ _id: this.role._id, isPayroll });
  }

  public async updateRoleFullName() {
    const isUpdated = await this.updateRole({
      _id: this.role._id,
      fullName: this.role.fullName
    });

    if (isUpdated) {
      this.showNotification = true;
    }
  }
  public async updateRoleDescription() {
    const isUpdated = this.updateRole({
      _id: this.role._id,
      description: this.role.description
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  @roles.Action
  private updateRole!: (role: UpdateRole) => Promise<boolean>;
}
