







































import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
import { Employee } from '../types/employee.type';

@Component({
  name: 'UpdateEmployeeBirthdate',
  components: { UpdateEmployee }
})
export default class UpdateEmployeeBirthdate extends Vue {
  @Prop() employee!: Employee;

  @Prop({ default: false }) showNotification!: boolean;

  public birthdateMenu = false;

  public updateDate(birthdate: Date) {
    this.$emit('birthdate', birthdate);
    this.birthdateMenu = false;
  }
}
