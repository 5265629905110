import { UpdateMyInfoInput } from '@/modules/my-account/types/update-my-info.input';
import { User } from '@/modules/settings/types/user.type';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import imageCompression from 'browser-image-compression';
@Module({ namespaced: true })
export default class Profile extends VuexModule {
  public avatar =
    'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light';
  public profile: User = {
    userName: '',
    lastName: '',
    firstName: '',
    status: '',
    mailNotification: false,
    role: {
      _id: '',
      fullName: '',
      isEmployee: false,
      isManager: false,
      isPaydocs: false,
      isPaydocsManager: false,
      isPayroll: false,
      isPeople: false,
      isRequests: false,
      isRequestsManager: false,
      isSettings: false,
      isTimeOff: false,
      isTimeOffManager: false
    }
  };

  public isManager = false;

  @Mutation
  setIsManager(isManager: boolean) {
    this.isManager = isManager;
  }

  @Mutation
  setAvatar(avatar: string) {
    this.avatar = avatar;
  }

  @Mutation
  setProfile(profile: User) {
    this.profile = profile;
  }

  @Action
  public async getMyProfile(): Promise<User | boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query User {
          getMyProfile {
            userName
            status
            avatar
            birthdate
            employeeId
            firstName
            lastName
            type
            mail
            phone
            mailNotification
            employee {
              teamId
            }

            role {
              fullName
              isEmployee
              isManager
              isPaydocs
              isPaydocsManager
              isPayroll
              isPeople
              isRequests
              isRequestsManager
              isSettings
              isTimeOff
              isTimeOffManager
              services {
                _id
                fullName
                description
                level
                prerequisites
                prerequisitesId
              }
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getMyProfile) {
      this.context.commit('setProfile', result.data.getMyProfile);
      this.context.commit('setAvatar', result.data.getMyProfile.avatar);
      return result.data.getMyProfile;
    }

    return false;
  }

  @Action
  public async updateMyAvatar(avatar: File): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500
    };

    const compressedAvatar = await imageCompression(avatar, options);

    const reader = new FileReader();

    reader.onload = async e => {
      const result = await apolloClient.mutate({
        mutation: gql`
          mutation User($avatar: String) {
            updateMyAvatar(avatar: $avatar)
          }
        `,
        variables: { avatar: reader.result },
        context: {
          uri: host,
          hasUpload: true,
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      });
      if (result && result.data && result.data.updateMyAvatar) {
        this.context.commit('setAvatar', reader.result);
        return result.data.updateMyAvatar;
      }
      return false;
    };
    reader.onerror = error => {
      console.log('Error: ', error);
      return false;
    };
    reader.readAsDataURL(compressedAvatar);
    return true;
  }

  @Action
  public async updateMyInfo(
    updateMyInfoInput: UpdateMyInfoInput
  ): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation User($updateMyInfoInput: UpdateMyInfoInput!) {
          updateMyInfo(updateMyInfoInput: $updateMyInfoInput)
        }
      `,
      variables: { updateMyInfoInput },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.updateMyInfo) {
      return result.data.updateMyInfo;
    }
    return false;
  }

  @Action
  public async updateMyPassword(
    updateMyPasswordInput: UpdateMyInfoInput
  ): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation User($updateMyPasswordInput: UpdateMyPasswordInput!) {
          updateMyPassword(updateMyPasswordInput: $updateMyPasswordInput)
        }
      `,
      variables: { updateMyPasswordInput },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.updateMyPassword) {
      return result.data.updateMyPassword;
    }
    return false;
  }

  @Action
  isPeople(): boolean {
    const isPeople = this.profile.role?.services?.filter(
      item => item.fullName === 'People'
    );
    return (isPeople && isPeople.length > 0) || false;
  }
}
