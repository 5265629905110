















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Team } from '../../types/team.type';
import { User } from '../../types/user.type';
import UpdateTeam from './update-team.vue';
import SelectUser from '../user/select-user.vue';

const teams = namespace('teams');

@Component({
  name: 'UpdateTeamLeader',
  components: { UpdateTeam, SelectUser }
})
export default class UpdateTeamLeader extends Vue {
  @Prop() team!: Team;

  @Prop({ default: false }) showNotification!: boolean;

  public selectUser(leader: User): void {
    this.setTeam({ ...this.team, leaderId: leader._id });
  }

  @teams.Mutation
  setTeam!: (team: Team) => void;
}
