

















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Team } from '../../types/team.type';
import Modal from '@/components/modal.vue';
import Value from '@/components/value.vue';

const teams = namespace('teams');

@Component({
  name: 'DeleteTeam',
  components: { Modal, Value }
})
export default class DeleteTeam extends Vue {
  @Prop() team!: Team;

  public showNotification = false;

  @teams.Action
  public deleteTeamFromRemote!: (_id: string) => void;
}
