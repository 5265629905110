














import { Component, Vue, Prop } from 'vue-property-decorator';
import ToDoListItem from './to-do-list-item.vue';
interface Item {
  title: string;
  subtitle: string;
}

@Component({
  components: {
    ToDoListItem
  }
})
export default class ToDoList extends Vue {
  @Prop() public toDo!: Item[];
  @Prop() public timeOffCount!: number;
  @Prop() public requestsCount!: number;

  get timeOffRequests() {
    return {
      title: 'Time off requests',
      subtitle: `You have ${this.timeOffCount} time off requests to review`
    };
  }

  get requests() {
    return {
      title: 'Requests',
      subtitle: `You have ${this.requestsCount} requests to review`
    };
  }

  get payrollRequests() {
    return {
      title: 'Payroll approval',
      subtitle: 'You have the monthly payroll approval'
    };
  }

  public onTimeOffRequests(): void {
    this.$router.push('/manager/timeoff');
  }

  public onRequests(): void {
    this.$router.push('/manager/requests');
  }

  public onPayroll(): void {
    this.$router.push('/manager/payroll');
  }
}
