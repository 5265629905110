import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { CreateUser } from '../types/create-user.type';
import { LinkAccountInput } from '../types/link-account.type';
import { UpdateUser } from '../types/update-user.type';
import { User } from '../types/user.type';

@Module({ namespaced: true })
export default class Users extends VuexModule {
  public newUser: CreateUser = {
    firstName: '',
    lastName: '',
    roleId: '',
    teamId: '',
    mail: ''
  };

  public user: User = {
    _id: '',
    firstName: '',
    lastName: '',
    roleId: '',
    role: {
      _id: '',
      fullName: '',
      description: ''
    },
    mail: ''
  };

  public users: User[] = [];

  public usersNotTeam: User[] = [];

  @Mutation
  public setNewUser(newUser: CreateUser): void {
    this.newUser = newUser;
  }

  @Mutation
  public clearNewUser(): void {
    this.newUser = {
      firstName: '',
      lastName: '',
      roleId: '',
      teamId: '',
      mail: ''
    };
  }

  @Mutation
  public setUser(user: User): void {
    this.user = user;
  }

  @Mutation
  public setUsers(users: User[]): void {
    this.users = users;
  }

  @Mutation
  public setUsersNotTeam(users: User[]): void {
    this.usersNotTeam = users;
  }

  @Action({ rawError: true })
  public async activeAccount(_id: string) {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation User($_id: String!) {
          activeAccount(_id: $_id)
        }
      `,
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.activeAccount)
      this.context.dispatch('getUsersFromRemote');
  }

  @Action({ rawError: true })
  public async disableAccount(_id: string) {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation User($_id: String!) {
          disableAccount(_id: $_id)
        }
      `,
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.activeAccount)
      this.context.dispatch('getUsersFromRemote');
  }

  @Action({ rawError: true })
  public async getUserFromRemote(_id: string) {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query User($_id: String!) {
          getUser(_id: $_id) {
            _id
            firstName
            lastName
            roleId
            role {
              _id
              fullName
            }
            teams {
              _id
              fullName
            }
            managerTeams {
              _id
              fullName
            }
            mail
            employeeId
            status
            employee {
              _id
              firstName
              lastName
            }
          }
        }
      `,
      variables: { _id },
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getUser)
      this.context.commit('setUser', result.data.getUser);
  }

  @Action({ rawError: true })
  public async getUsersFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query User {
          findAllUsers {
            _id
            avatar
            phone
            firstName
            lastName
            roleId
            role {
              _id
              fullName
            }
            teams {
              _id
              fullName
            }
            mail
            employeeId
            managerTeams {
              _id
              fullName
            }
            status
            employee {
              _id
              firstName
              lastName
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.findAllUsers)
      this.context.commit('setUsers', result.data.findAllUsers);
  }

  @Action({ rawError: true })
  public async getUsersNotTeamFromRemote(teamId: string) {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query User($_id: String!) {
          findAllUsersNotTeam(_id: $_id) {
            _id
            firstName
            lastName
            roleId
            role {
              _id
              fullName
            }
            mail
          }
        }
      `,
      variables: { _id: teamId },
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.findAllUsersNotTeam) {
      this.context.commit('setUsersNotTeam', result.data.findAllUsersNotTeam);
      return true;
    }
    return false;
  }

  @Action
  public async createUser(): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const { newUser } = this;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation User($createUserInput: CreateUserInput!) {
          createUser(createUserInput: $createUserInput) {
            _id
            firstName
            lastName
            roleId
            mail
          }
        }
      `,
      variables: { createUserInput: newUser },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.createUser) {
      this.context.commit('clearNewUser');
      this.context.dispatch('getUsersFromRemote');
      return true;
    }
    return false;
  }

  @Action
  public async updateUser(user: UpdateUser): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation User($updateUserInput: UpdateUserInput!) {
          updateUser(updateUserInput: $updateUserInput)
        }
      `,
      variables: { updateUserInput: user },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.updateUser) {
      await this.context.dispatch('getUserFromRemote', user._id);
      await this.context.dispatch('getUsersFromRemote');
      return true;
    }
    return false;
  }

  @Action({ rawError: true })
  public async deleteUserFromRemote(_id: string): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation User($_id: String!) {
          deleteUser(_id: $_id)
        }
      `,
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.deleteUser) {
      this.context.dispatch('getUsersFromRemote');
      return true;
    }
    return false;
  }

  @Action
  public async linkAccount({
    userId,
    employeeId
  }: LinkAccountInput): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation User($linkAccountInput: LinkAccountInput!) {
          linkAccount(linkAccountInput: $linkAccountInput)
        }
      `,
      variables: { linkAccountInput: { userId, employeeId } },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.linkAccount) {
      this.context.dispatch('getUsersFromRemote');
      this.context.dispatch('getUserFromRemote', userId);
      return true;
    }
    return false;
  }
}
