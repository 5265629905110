
















import { Component, Vue } from 'vue-property-decorator';
import Value from '@/components/value.vue';
import { namespace } from 'vuex-class';

const application = namespace('application');

@Component({
  name: 'WaitingRoom',
  components: { Value }
})
export default class WaitingRoom extends Vue {
  @application.State
  public logoPath!: string;

  private created(): void {
    this.setIsNavigation(false);
  }

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;
}
