






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '../../types/user.type';
import UpdateUser from './update-user.vue';

@Component({
  name: 'UpdateUserFirstNameAndLastName',
  components: { UpdateUser }
})
export default class UpdateUserFirstNameAndLastName extends Vue {
  @Prop() user!: User;

  @Prop({ default: false }) showNotification!: boolean;
}
