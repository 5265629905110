








import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Holiday from '../types/holiday.type';
import EmployeeListItem from './employee-list-item.vue';

const overview = namespace('overview');

@Component({ name: 'EmployeesOnHoliday', components: { EmployeeListItem } })
export default class EmployeesOnHoliday extends Vue {
  @overview.State
  public nextHolidays!: Holiday[];

  private async created() {
    await this.getNextHolidaysFromRemote();
  }

  @overview.Action
  private getNextHolidaysFromRemote!: () => Promise<void>;
}
