



















import { Component, Prop, Vue } from 'vue-property-decorator';

import { User } from '../../types/user.type';

@Component({
  name: 'UserListItem'
})
export default class UserListItem extends Vue {
  @Prop() user!: User;
}
