

















import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
import { Employee } from '../types/employee.type';
import { Team } from '@/modules/settings/types/team.type';
import SelectTeam from '@/modules/settings/components/team/select-team.vue';

@Component({
  name: 'UpdateEmployeeTeam',
  components: { UpdateEmployee, SelectTeam }
})
export default class UpdateEmployeeTeam extends Vue {
  @Prop() employee!: Employee;

  @Prop({ default: false }) showNotification!: boolean;

  public selectTeam(team: Team) {
    this.$emit('team', team);
  }
}
