
















import { User } from '@/modules/settings/types/user.type';
import { namespace } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Badge from './badge.vue';

type Item = {
  action: string;
  title: string;
  noAction: boolean;
  path: string;
  items: [];
  enable: boolean;
  manager: boolean;
};

const timeOff = namespace('timeOff');
const requests = namespace('requests');

@Component({ components: { Badge } })
export default class BadgeMenu extends Vue {
  @Prop() item!: Item;
  @Prop() profile!: User;

  @timeOff.State
  public timeOffCount!: number;

  @requests.State
  public requestsCount!: number;

  private async created(): Promise<void> {
    if (this.item) {
      this.resolver(this.item);
    }
  }

  private async resolver(item: Item): Promise<void> {
    if (item && item.title === 'Time Off' && item.manager) {
      await this.getTimeOffForMeCount();
    } else if (item && item.title === 'Requests' && item.manager) {
      await this.getRequestsForMeCount();
    }
  }

  @timeOff.Action
  getTimeOffForMeCount!: () => Promise<void>;

  @requests.Action
  getRequestsForMeCount!: () => Promise<void>;
}
