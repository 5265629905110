
































































































































































































































































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Request from './../types/request.type';

const requests = namespace('requests');
const application = namespace('application');

@Component({
  name: 'RequestsManager',
  components: { Header }
})
export default class Requests extends Vue {
  public drawer = false;

  public search = '';

  public activeTab = 0;

  public dialogPaidLeaveRequest = false;

  public requestSelected = {};

  public searchFilter = '';

  public review = '';

  public currentHeaders = [
    {
      text: 'Fullname',
      align: 'start',
      value: 'employee'
    },
    {
      text: 'Creation date',
      value: 'creationDate'
    },
    { text: 'Category', value: 'category' },
    { text: 'Type', value: 'type' },
    { text: 'Description', value: 'date' },
    { text: 'Status', value: 'status', width: '150' },
    { text: '', value: 'actions', sortable: false }
  ];

  public closedHeaders = [
    {
      text: 'Fullname',
      align: 'start',
      value: 'employee'
    },
    {
      text: 'Creation date',
      value: 'creationDate'
    },
    { text: 'Category', value: 'category' },
    { text: 'Type', value: 'type' },
    { text: 'Description', value: 'date' },
    { text: 'Status', value: 'status' }
  ];

  @requests.State
  public requests!: Request[];

  private async created(): Promise<void> {
    this.setIsNavigation(true);
    this.setTitle('Request(s)');
    await this.getRequestsForMeFromRemote();
  }

  public onRequest(request: Request) {
    this.requestSelected = request;
    this.drawer = true;
  }

  public onAll(): void {
    this.activeTab = 0;
  }

  public onCurrent(): void {
    this.activeTab = 1;
  }

  public onApproved(): void {
    this.activeTab = 2;
  }

  public onRejected(): void {
    this.activeTab = 3;
  }

  public filterRequests(value: unknown, search: string, item: Request) {
    switch (this.activeTab) {
      case 0:
        if (this.search.length) {
          return (
            value != null &&
            this.search != null &&
            typeof value === 'string' &&
            (value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
              (item.employee &&
                (item.employee.firstName
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  item.employee.lastName
                    .toString()
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
              (item.note &&
                item.note
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1))
          );
        }
        return true;
      case 1:
        if (this.search.length) {
          return (
            value != null &&
            this.search != null &&
            typeof value === 'string' &&
            (value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
              (item.employee &&
                (item.employee.firstName
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  item.employee.lastName
                    .toString()
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
              (item.note &&
                item.note
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
            item.processed === false
          );
        }
        return item.processed === false;
      case 2:
        if (this.search.length) {
          return (
            value != null &&
            this.search != null &&
            typeof value === 'string' &&
            (value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
              (item.employee &&
                (item.employee.firstName
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  item.employee.lastName
                    .toString()
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
              (item.note &&
                item.note
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
            item.processed === true &&
            item.status === 'approved'
          );
        }
        return item.processed === true && item.status === 'approved';
      case 3:
        if (this.search.length) {
          return (
            value != null &&
            this.search != null &&
            typeof value === 'string' &&
            (value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
              (item.employee &&
                (item.employee.firstName
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  item.employee.lastName
                    .toString()
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
              (item.note &&
                item.note
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1))
          );
        }
        return true;
    }
  }

  public async accept(_id: string) {
    const { review } = this;
    const result = this.approveRequest({ _id, review });
    if (result) {
      this.drawer = false;
    }
  }

  public async decline(_id: string) {
    const { review } = this;
    const result = this.rejectRequest({ _id, review });
    if (result) {
      this.drawer = false;
    }
  }

  public onRequests(): void {
    /* this.search = 'waiting'; */
    this.searchFilter = '*';
    this.activeTab = 0;
  }

  public onHistory(): void {
    /* this.search = 'processed'; */
    this.searchFilter = '*';
  }

  public searchByName(args: string) {
    this.search = args;
    this.searchFilter = '*';
  }

  @requests.Action
  getRequestsForMeFromRemote!: () => void;

  @requests.Action
  approveRequest!: ({
    _id,
    review
  }: {
    _id: string;
    review: string;
  }) => Request | null;

  @requests.Action
  rejectRequest!: ({
    _id,
    review
  }: {
    _id: string;
    review: string;
  }) => Request | null;

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;
}
