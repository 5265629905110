







import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class MyInfoItem extends Vue {
  @Prop()
  public title!: string;

  @Prop()
  public subtitle!: string;

  @Prop()
  public buttonTitle!: string;
}
