







































import { Component, Prop, Vue } from 'vue-property-decorator';
import Update from './update.vue';
import { User } from '@/modules/settings/types/user.type';

@Component({
  name: 'UpdateBirthdate',
  components: { Update }
})
export default class UpdateBirthdate extends Vue {
  @Prop() user!: User;

  @Prop({ default: false }) showNotification!: boolean;

  public birthdateMenu = false;

  public updateDate(birthdate: Date) {
    this.$emit('birthdate', birthdate);
    this.birthdateMenu = false;
  }
}
