




import { Payslip } from '@/modules/paydocs/types/payslip.type';
import { namespace } from 'vuex-class';
import { Vue, Prop, Component } from 'vue-property-decorator';

interface Item {
  date: string;
  avatar: string;
  title: string;
  subtitle: string;
}
const paydocs = namespace('paydocs');
@Component({ name: 'MyLastPayslip' })
export default class MyLastPayslip extends Vue {
  @paydocs.State
  public myLastPayslip!: Payslip;

  private async created(): Promise<void> {
    await this.getMyLastPayslip();
  }

  public async onButton() {
    if (this.myLastPayslip && this.myLastPayslip.fileId) {
      console.log('this.myLastPayslip.fileId', this.myLastPayslip.fileId);
      await this.downloadPaydoc(this.myLastPayslip.fileId);
    }
  }
  @paydocs.Action
  private downloadPaydoc!: (fileId: string) => void;

  @paydocs.Action
  private getMyLastPayslip!: () => Promise<void>;
}
