import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { User } from '../types/user.type';

@Module({ namespaced: true })
export default class Managers extends VuexModule {
  public managers: User[] = [];

  public managersNotTeam: User[] = [];

  @Mutation
  public setManagers(managers: User[]): void {
    this.managers = managers;
  }

  @Mutation
  public setManagersNotTeam(managersNotTeam: User[]): void {
    this.managersNotTeam = managersNotTeam;
  }

  @Action({ rawError: true })
  public async getManagersFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Users {
          findAllManagers {
            _id
            firstName
            lastName
            roleId
            role {
              _id
              fullName
            }
            teams {
              _id
              fullName
            }
            mail
            employeeId
            status
            employee {
              _id
              firstName
              lastName
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.findAllManagers)
      this.context.commit('setManagers', result.data.findAllManagers);
  }

  @Action({ rawError: true })
  public async getManagersNotTeamFromRemote(teamId: string) {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query User($_id: String!) {
          findAllManagersNotTeam(_id: $_id) {
            _id
            firstName
            lastName
            roleId
            role {
              _id
              fullName
            }
            mail
          }
        }
      `,
      variables: { _id: teamId },
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.findAllManagersNotTeam) {
      this.context.commit(
        'setManagersNotTeam',
        result.data.findAllManagersNotTeam
      );
      return true;
    }
    return false;
  }
}
