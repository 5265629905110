import { RouteConfig } from 'vue-router';
import Payslip from './../views/payslip.vue';
import Paydocs from './../views/paydocs.vue';
import PaydocsManager from './../views/paydocs-manager.vue';
import auth from '@/guards/auth.guard';

const payslipsRoutes: Array<RouteConfig> = [
  {
    path: '/payslip/:id',
    name: 'Payslip',
    component: Payslip,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/paydocs',
    name: 'Paydocs',
    component: Paydocs,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/manager/paydocs',
    name: 'PaydocsManager',
    component: PaydocsManager,
    meta: {
      middleware: [auth]
    }
  }
];

export default payslipsRoutes;
