

































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AddPayrollSummariesReceiver from './add-payroll-summaries-receiver.vue';

const application = namespace('application');

@Component({
  name: 'PayrollSummariesReceivers',
  components: { Header, AddPayrollSummariesReceiver }
})
export default class PayrollSummariesReceivers extends Vue {
  public headers = [
    { text: 'Firstname', value: 'FirstName' },
    { text: 'Lastname', value: 'lastName' },
    { text: 'Role', value: 'role' }
  ];
  public employees = [];
  public search = '';
}
