




















































import { User } from '@/modules/settings/types/user.type';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import BadgeMenu from './badge-menu.vue';

const application = namespace('application');

@Component({
  name: 'Menu',
  components: { BadgeMenu }
})
export default class Menu extends Vue {
  @application.State
  public mode!: string;

  @application.State
  public logoPath!: string;

  @Prop() isMenu!: boolean;

  @Prop() profile!: User;

  @Prop() items!: [];

  public showMenu = false;

  public itemsAdmin = [
    {
      action: 'mdi-cog',
      title: 'Settings',
      path: '/manager/settings',
      items: [],
      enable: true
    }
  ];

  private onButton(path: string) {
    this.$router.push(path);
  }

  get menu() {
    const m = [
      {
        action: 'mdi-view-dashboard',
        title: 'Overview',
        noAction: false,
        path: '/manager/overview',
        items: [],
        enable: this.profile?.role?.isManager || false,
        manager: true
      },
      {
        action: 'mdi-account-group',
        title: 'People',
        path: '/employees',
        items: [],
        enable: this.is('People'),
        manager: true
      },
      {
        action: 'mdi-file-document-multiple-outline',
        title: 'Pay',
        path: '/manager/paydocs',
        items: [],
        enable: this.is('Pay Manager') || false,
        manager: true
      },
      {
        action: 'mdi-calendar-multiselect',
        title: 'Time Off',
        path: '/manager/timeOff',
        items: [],
        enable: this.is('Time Off Manager') || false,
        manager: true
      },
      {
        action: 'mdi-frequently-asked-questions',
        title: 'Request(s)',
        path: '/manager/requests',
        items: [],
        enable: this.is('Request Manager') || false,
        manager: true
      },
      {
        action: 'mdi-cog',
        title: 'Settings',
        path: '/manager/settings',
        items: [],
        enable: true || false,
        manager: true
      },
      {
        action: 'mdi-view-dashboard',
        title: 'Overview',
        noAction: false,
        path: '/overview',
        items: [],
        enable: true,
        manager: false
      },
      {
        action: 'mdi-information',
        title: 'My Info',
        path: '/myInfo',
        items: [],
        enable: false,
        manager: false
      },

      {
        action: 'mdi-file-document-multiple-outline',
        title: 'Pay',
        path: '/paydocs',
        items: [],
        enable: this.is('Pay') || false,
        manager: false
      },
      {
        action: 'mdi-calendar-multiselect',
        title: 'Timeoff',
        path: '/timeOff',
        items: [],
        enable: this.is('Time Off') || false,
        manager: false
      },
      {
        action: 'mdi-frequently-asked-questions',
        title: 'Request(s)',
        path: '/requests',
        items: [],
        enable: this.is('Request') || false,
        manager: false
      }
    ];
    return m;
  }

  is(service: string): boolean {
    const exist = this.profile.role?.services?.filter(
      item => item.fullName === service
    );
    return (exist && exist.length > 0) || false;
  }
}
