import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import router from '@/router';
import { LoginInput } from '../types/loginInput.type';

@Module({ namespaced: true })
export default class Login extends VuexModule {
  public user: LoginInput = {
    userName: '',
    password: ''
  };

  public id = '';

  @Mutation
  public setUserName(userName: string): void {
    this.user = { ...this.user, userName };
  }

  @Mutation
  public setPassword(password: string): void {
    this.user = { ...this.user, password };
  }

  @Mutation
  public setId(id: string): void {
    this.id = id;
  }

  @Action
  public saveUserName(userName: string): void {
    this.context.commit('setUserName', userName);
  }

  @Action
  public savePassword(password: string): void {
    this.context.commit('setPassword', password);
  }

  @Action({ rawError: true })
  public async connectOnRemote(): Promise<boolean> {
    const host = process.env.VUE_APP_API_AUTH;
    const result = await apolloClient.query({
      query: gql`
        query Auth($user: LoginInput!) {
          login(user: $user) {
            userName
            success
            token
            host
          }
        }
      `,
      variables: { user: this.user },
      context: {
        uri: host
      },
      fetchPolicy: 'network-only'
    });
    if (
      result &&
      result.data &&
      result.data.login &&
      result.data.login.success &&
      result.data.login.host &&
      result.data.login.userName
    ) {
      await this.context.dispatch(
        'auth/saveIsConnected',
        result.data.login.success,
        {
          root: true
        }
      );
      await this.context.dispatch('auth/saveToken', result.data.login.token, {
        root: true
      });
      await this.context.dispatch('auth/saveHost', result.data.login.host, {
        root: true
      });
      const profile = await this.context.dispatch(
        'profile/getMyProfile',
        result.data.login.host,
        {
          root: true
        }
      );
      if (
        profile &&
        (profile.status === 'active' || profile.type === 'Admin')
      ) {
        if (profile.type === 'Admin') {
          await this.context.commit('application/setMode', 'admin', {
            root: true
          });
          router.push({ path: '/admin' });
        } else if (profile.role && profile.role.isManager) {
          await this.context.commit('application/setMode', 'manager', {
            root: true
          });
          router.push({ path: '/manager/overview' });
        } else {
          await this.context.commit('application/setMode', 'employee', {
            root: true
          });
          router.push({ path: '/overview' });
        }
      } else {
        router.push({ path: '/waiting-room' });
      }
      this.context.commit('setUserName', '');
      this.context.commit('setPassword', '');
      return true;
    } else {
      return false;
    }
  }

  @Action({ rawError: true })
  public async resetPassword(userName: string): Promise<boolean> {
    const host = process.env.VUE_APP_API_AUTH;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Auth($userName: String!) {
          resetPassword(userName: $userName)
        }
      `,
      variables: { userName },
      context: {
        uri: host
      }
    });
    if (result && result.data && result.data.resetPassword) {
      return result.data.resetPassword;
    }
    return false;
  }

  @Action({ rawError: true })
  public async updatePassword(updatePasswordInput: {
    token: string;
    password: string;
  }): Promise<boolean> {
    const host = process.env.VUE_APP_API_AUTH;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Auth($updatePasswordInput: UpdatePasswordInput!) {
          updatePassword(updatePasswordInput: $updatePasswordInput)
        }
      `,
      variables: { updatePasswordInput },
      context: {
        uri: host
      }
    });
    if (result && result.data && result.data.updatePassword) {
      return result.data.updatePassword;
    }
    return false;
  }

  @Action
  public logOut() {
    this.context.commit('setToken', '');
    this.context.commit('setHost', '');
    this.context.dispatch('saveIsConnected', false);
    router.push({ path: '/login' });
  }

  @Action
  public saveId(id: string) {
    this.context.commit('setId', id);
  }
}
