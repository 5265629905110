import employeesRoutes from '@/modules/employees/routes';
import loginRoutes from '@/modules/login/routes';
import myAccountRoutes from '@/modules/my-account/routes';
import overviewRoutes from '@/modules/overview/routes';
import paydocsRoutes from '@/modules/paydocs/routes';
import requestsRoutes from '@/modules/requests/routes';
import timeOffRoutes from '@/modules/timeOff/routes';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import settingsRoutes from '@/modules/settings/routes';
import registerRoutes from '@/modules/register/routes';
import auth from '@/guards/auth.guard';
import adminRoutes from '@/modules/admin/routes';
import projectsRoutes from '@/modules/projects/routes';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  ...loginRoutes,
  ...employeesRoutes,
  ...timeOffRoutes,
  ...overviewRoutes,
  ...requestsRoutes,
  ...myAccountRoutes,
  ...paydocsRoutes,
  ...settingsRoutes,
  ...registerRoutes,
  ...projectsRoutes,
  ...adminRoutes
];

const router = new VueRouter({
  routes
});

function nextFactory(
  context: any,
  middleware: { (context: { next: () => {} }): void }[],
  index: number
) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters: any) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (!to || !to.meta || !to.meta.middleware) {
    return next();
  }

  const middleware = Array.isArray(to.meta.middleware)
    ? to.meta.middleware
    : [to.meta.middleware];

  const context = {
    to,
    from,
    next,
    router,
    store: router.app.$store
  };

  const nextMiddleware = nextFactory(context, middleware, 1);

  return middleware[0]({
    ...context,
    next: nextMiddleware
  });
});

export default router;
