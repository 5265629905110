import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import router from '@/router';
import TimeLineItem from '../types/TimeLineItem.type';
import ToDoItem from '../types/ToDoItem.type';
import HolidayItem from '../types/holiday.type';
import Celebration from '../types/celebration.type';
import Holiday from '../types/holiday.type';

@Module({ namespaced: true })
export default class Overview extends VuexModule {
  public timeline: TimeLineItem[] = [];
  public toDo: ToDoItem[] = [];
  public nextHolidays: HolidayItem[] = [];
  public nextCelebrations: Celebration[] = [];

  @Mutation
  setTimeLine(timeline: TimeLineItem[]) {
    this.timeline = timeline;
  }

  @Mutation
  setToDo(toDo: ToDoItem[]) {
    this.toDo = toDo;
  }

  @Mutation
  setNextCelebrations(nextCelebrations: Celebration[]) {
    this.nextCelebrations = nextCelebrations;
  }

  @Mutation
  setNextHolidays(nextHolidays: Holiday[]) {
    this.nextHolidays = nextHolidays;
  }

  @Action
  getTimeLineFromRemote() {
    const result = [
      {
        date: 'SEP 13, 2020',
        title: 'SALARY CHANGE',
        subtitle:
          "Manager John set Erica Cruz's hourly rate to 25€ effective on May 5,2013.",
        avatar:
          'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'
      },
      {
        date: 'SEP 13, 2020',
        title: 'SALARY CHANGE',
        subtitle:
          "Manager John set Erica Cruz's hourly rate to 25€ effective on May 5,2013.",
        avatar:
          'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'
      },
      {
        date: 'SEP 13, 2020',
        title: 'SALARY CHANGE',
        subtitle:
          "Manager John set Erica Cruz's hourly rate to 25€ effective on May 5,2013.",
        avatar:
          'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light'
      }
    ];

    this.context.commit('setTimeLine', result);
  }

  @Action
  public async getNextHolidaysFromRemote(): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query TimeOff {
          getNextHolidays {
            firstName
            lastName
            startDate
            daysTillHoliday
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getNextHolidays)
      this.context.commit('setNextHolidays', result.data.getNextHolidays);
  }

  @Action
  public async getNextCelebrations() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Employee {
          getNextCelebrations {
            firstName
            lastName
            birthdate
            daysTillBirthday
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getNextCelebrations)
      this.context.commit(
        'setNextCelebrations',
        result.data.getNextCelebrations
      );
  }
}
