






































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const application = namespace('application');

@Component({ name: 'Subscription' })
export default class Subscription extends Vue {
  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;
}
