











import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Employee } from '../types/employee.type';

const paydocs = namespace('paydocs');

@Component({
  name: 'SelectCategory'
})
export default class SelectCategory extends Vue {
  @Prop() category!: string;

  categories = ['Payslip', 'Tax', 'Other'];
}
