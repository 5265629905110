






























































































































import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User as UserT } from '../types/user.type';
import UserList from './user/user-list.vue';
import User from './user/user.vue';

const users = namespace('users');
const application = namespace('application');

@Component({
  name: 'Users',
  components: { Header, User, UserList }
})
export default class Users extends Vue {
  @Prop({ default: false }) public onlyDataTable!: boolean;

  @Prop({ default: 'Users' }) public title!: string;

  @users.State
  public users!: UserT[];

  public displayUsers: UserT[] = [];

  public activeTab = 0;

  public search = '';
  public searchFilter = '*';

  public isList = 0;

  public drawer = false;

  @Prop({
    default: () => [
      {
        text: 'Fullname',
        value: 'firstName'
      },
      {
        text: 'Role',
        value: 'role'
      },
      {
        text: 'Mail',
        value: 'mail'
      },
      {
        text: 'Phone',
        value: 'phone'
      },
      {
        text: 'Status',
        value: 'status',
        width: '200px'
      },
      { text: '', value: 'actions' }
    ]
  })
  public headers!: [];

  private async created(): Promise<void> {
    this.setTitle('Users Settings');
    await this.getUsersFromRemote();
    this.displayUsers = this.users;
  }

  private updated(): void {
    this.getUsersFromRemote();
  }

  public onList() {
    this.isList = 0;
  }

  public onCard() {
    this.isList = 1;
  }

  private openDrawer(): void {
    this.drawer = true;
  }

  public handleUser(user: User) {
    this.setUser(user);
    this.openDrawer();
  }

  public onUser(user: User): void {
    this.$emit('user', user);
    this.setUser(user);
    this.drawer = true;
  }

  public searchByName(args: string) {
    this.search = args;
    this.displayUsers = this.users.filter(user => {
      if (this.activeTab === 2) {
        return (
          user != null &&
          user.firstName &&
          this.search != null &&
          typeof args === 'string' &&
          user.firstName
            .toLocaleUpperCase()
            .indexOf(args.toLocaleUpperCase()) !== -1 &&
          user.status === 'active'
        );
      } else if (this.activeTab === 1) {
        return (
          user != null &&
          user.firstName &&
          typeof args === 'string' &&
          user.firstName
            .toLocaleUpperCase()
            .indexOf(args.toLocaleUpperCase()) !== -1 &&
          !user.status
        );
      }
      return (
        user != null &&
        ((user.firstName &&
          typeof args === 'string' &&
          user.firstName
            .toLocaleUpperCase()
            .indexOf(args.toLocaleUpperCase()) !== -1) ||
          (user.lastName &&
            typeof args === 'string' &&
            user.lastName
              .toLocaleUpperCase()
              .indexOf(args.toLocaleUpperCase()) !== -1)) &&
        true
      );
    });
  }

  public onChangeTab(tab: number) {
    this.displayUsers = this.users.filter(user => {
      if (tab === 2) {
        return (
          user != null &&
          ((user.firstName &&
            typeof this.search === 'string' &&
            user.firstName
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
            (user.lastName &&
              typeof this.search === 'string' &&
              user.lastName
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
          user.status === 'active'
        );
      } else if (tab === 1) {
        return (
          user != null &&
          ((user.firstName &&
            typeof this.search === 'string' &&
            user.firstName
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
            (user.lastName &&
              typeof this.search === 'string' &&
              user.lastName
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
          !user.status
        );
      }
      return (
        user != null &&
        ((user.firstName &&
          typeof this.search === 'string' &&
          user.firstName
            .toLocaleUpperCase()
            .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
          (user.lastName &&
            typeof this.search === 'string' &&
            user.lastName
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
        true
      );
    });
  }

  @users.Mutation
  private setUser!: (user: User) => void;

  @users.Action
  private getUsersFromRemote!: () => Promise<void>;

  @application.Mutation
  setTitle!: (title: string) => void;
}
