




































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User } from './modules/settings/types/user.type';
import Menu from '@/components/menu.vue';
import MenuAppBar from '@/components/menu-app-bar.vue';
import Footer from '@/components/footer.vue';
import Profile from '@/components/profile.vue';
import MenuHome from '@/components/menu-home.vue';

const auth = namespace('auth');
const profile = namespace('profile');
const application = namespace('application');

@Component({
  name: 'App',
  components: { Menu, Footer, MenuAppBar, Profile, MenuHome }
})
export default class App extends Vue {
  @profile.State
  public profile!: User;

  @auth.State
  public isConnected!: boolean;

  @application.State
  public isNavigation!: boolean;

  @application.State
  public isMenuHome!: boolean;

  @application.State
  public title!: string;

  created() {
    document.title = 'Payroll';
  }
}
