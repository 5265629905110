












































































































import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Team as TeamType } from '../../types/team.type';
import Label from '@/components/label.vue';
import LabelAndValue from '@/components/label-and-value.vue';
import Members from '../members.vue';
import Managers from '../managers.vue';
import AddTeamMember from '../employee/add-team-member.vue';
import AddTeamManager from '../user/add-team-manager.vue';
import ChooseLeader from '../user/choose-leader.vue';
import DeleteTeam from './delete-team.vue';
import UpdateTeamFullName from './update-team-fullname.vue';
import UpdateTeamDescription from './update-team-description.vue';
import UpdateTeamLeader from './update-team-leader.vue';
import { UpdateTeam } from '../../types/update-team.type';
import { User } from '../../types/user.type';
import { RemoveTeamManager } from '../../types/remove-manager.type';
import { RemoveTeamMember } from '../../types/remove-team-member.type';
import { Employee } from '../../types/employee.type';

const teams = namespace('teams');

@Component({
  name: 'Team',
  components: {
    AddTeamMember,
    AddTeamManager,
    ChooseLeader,
    Header,
    Label,
    LabelAndValue,
    Managers,
    Members,
    DeleteTeam,
    UpdateTeamDescription,
    UpdateTeamFullName,
    UpdateTeamLeader
  }
})
export default class Team extends Vue {
  @Prop()
  public team!: TeamType;

  public showNotification = false;

  public activeTab = 0;

  public headers = [
    {
      text: 'Employee',
      value: 'firstName'
    },
    {
      text: '',
      value: 'actions'
    }
  ];

  public headersMangers = [
    {
      text: 'Manager',
      value: 'firstName'
    },
    {
      text: '',
      value: 'actions'
    }
  ];

  public close(): void {
    this.showNotification = false;
  }

  public async cancelUpdate(): Promise<void> {
    await this.getTeamFromRemote(this.team._id);
  }

  public async updateTeamFullName() {
    const isUpdated = await this.updateTeam({
      _id: this.team._id,
      fullName: this.team.fullName
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public async updateTeamDescription() {
    const isUpdated = this.updateTeam({
      _id: this.team._id,
      description: this.team.description
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public async updateTeamLeader() {
    const isUpdated = await this.updateTeam({
      _id: this.team._id,
      leaderId: this.team.leaderId
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public async onRemoveManager(manager: User) {
    if (manager && manager._id)
      this.removeManager({
        teamId: this.team._id,
        managerId: manager._id
      });
  }

  public async onRemoveMember(member: Employee) {
    if (member && member._id)
      await this.removeTeamMember({
        teamId: this.team._id,
        memberId: member._id
      });
  }

  @teams.Action
  removeManager!: ({
    teamId,
    managerId
  }: RemoveTeamManager) => Promise<boolean>;

  @teams.Action
  removeTeamMember!: (
    removeTeamMemberInput: RemoveTeamMember
  ) => Promise<boolean>;

  @teams.Action
  getTeamFromRemote!: (_id: string) => Promise<void>;

  @teams.Action
  updateTeam!: (team: UpdateTeam) => Promise<boolean>;
}
