










import { Component, Prop, Vue } from 'vue-property-decorator';
import Label from './label.vue';
import Value from './value.vue';

@Component({ components: { Label, Value } })
export default class LabelAndValue extends Vue {
  @Prop() private label!: string;

  @Prop() private value!: string;
}
