import auth from '@/guards/auth.guard';
import { RouteConfig } from 'vue-router';
import Register from './../views/register.vue';
import WaitingRoom from './../views/waiting-room.vue';


const registerRoutes: Array<RouteConfig> = [
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/waiting-room',
    name: 'WaitingRoom',
    component: WaitingRoom
  }
];

export default registerRoutes;
