






















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import { CreateEmployee } from '../types/create-employee.type';
import CountrySelect from '@/components/country-select.vue';
import SelectTeam from '@/modules/settings/components/team/select-team.vue';
import { Team } from '@/modules/settings/types/team.type';

const employees = namespace('employees');

@Component({
  name: 'NewEmployee',
  components: { Modal, CountrySelect, SelectTeam }
})
export default class NewEmployee extends Vue {
  @employees.State
  newEmployee!: CreateEmployee;

  public dateOfHireMenu = false;
  public birthdateMenu = false;
  public showNotification = false;
  public loading = false;
  public contractType = ['CDD', 'CDI'];

  public familySituation = ['Married', 'Unmarried'];

  public async save() {
    this.loading = true;
    const result = await this.createEmployee();
    if (result) {
      this.showNotification = true;
      this.clearNewEmployee();
    }
    this.loading = false;
  }

  public closeDialog() {
    this.showNotification = false;
  }

  public selectTeam(team: Team) {
    this.setTeamId(team._id);
  }

  @employees.Mutation
  setNationality!: (nationality: string) => void;

  @employees.Mutation
  setTeamId!: (teamId: string) => void;

  @employees.Mutation
  clearNewEmployee!: () => void;

  @employees.Action
  createEmployee!: () => boolean;
}
