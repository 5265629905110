




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Role } from '../../types/role.type';
import UpdateRole from './update-role.vue';

@Component({
  name: 'UpdateTeamDescription',
  components: { UpdateRole }
})
export default class UpdateTeamDescription extends Vue {
  @Prop() role!: Role;

  @Prop({ default: false }) showNotification!: boolean;
}
