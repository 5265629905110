













































































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue';
import SelectEmployee from './../components/select-employee.vue';
import SelectCategory from './../components/select-category.vue';
import UploadPaydoc from './../components/upload-paydoc.vue';
import { Employee } from '../types/employee.type';
import { namespace } from 'vuex-class';
import { Payslip } from '../types/payslip.type';
import AddDocument from '../components/add-document.vue';
import Payroll from '../components/payroll.vue';
import PaydocsTracker from '../components/paydocs-tracker.vue';
import AddPayroll from '../components/add-payroll.vue';
import PaydocsRequests from '../components/paydocs-requests.vue';
import { User } from '@/modules/settings/types/user.type';

const paydocs = namespace('paydocs');
const application = namespace('application');
const profile = namespace('profile');

@Component({
  name: 'PaydocsManager',
  components: {
    Header,
    UploadPaydoc,
    SelectEmployee,
    SelectCategory,
    AddDocument,
    AddPayroll,
    Payroll,
    PaydocsRequests,
    PaydocsTracker
  }
})
export default class PaydocsManager extends Vue {
  @paydocs.State
  public paydocs!: Payslip[];

  @profile.State
  profile!: User;

  public drawer = false;
  public employee = {};
  public paydoc = [];
  public period: Date | string = '';
  public category = '';
  public requestSelected: Payslip = {};

  private created(): void {
    this.setPaydocs([]);
    this.setIsNavigation(true);
    this.setTitle('Pay');
  }

  public async uploadSuccess(): Promise<void> {
    this.employee = {};
    this.category = '';
    this.paydoc = [];
    await this.getPaydocsRequestsForMeFromRemote();
  }

  public onRequest(request: Payslip): void {
    this.drawer = true;
    this.requestSelected = request;
  }

  public setEmployee(employee: Employee): void {
    this.employee = { ...employee };
    this.getPaydocsFromRemote(employee._id);
  }

  public setCategory(category: string): void {
    this.category = category;
  }

  public setPeriod(period: Date): void {
    this.period = period;
  }

  public setPaydoc(paydoc: []): void {
    this.paydoc = paydoc;
  }

  public showItem(fileId: string): void {
    this.downloadPaydoc(fileId);
  }

  public is(service: string) {
    const exist = this.profile.role?.services?.filter(
      item => item.fullName === service
    );
    return exist && exist.length > 0;
  }

  @paydocs.Mutation
  private setPaydocs!: (paydocs: []) => void;

  @paydocs.Action
  private getPaydocsFromRemote!: (_id: string) => void;

  @paydocs.Action
  private getPaydocsRequestsForMeFromRemote!: () => Promise<void>;

  @paydocs.Action
  private downloadPaydoc!: (fileId: string) => void;

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;
}
