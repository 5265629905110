




import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UploadDocument from '@/components/upload-document.vue';
import { Employee } from '@/modules/employees/types/employee.type';

const paydocs = namespace('paydocs');

@Component({
  name: 'UploadPayroll',
  components: { UploadDocument }
})
export default class UploadPayroll extends Vue {
  @Prop() public employee!: Employee;

  @Prop() public category!: string;

  @Prop() public period!: Date;

  @Prop() public payroll!: [];

  @Prop() public disabled!: boolean;

  public async upload(file: any) {
    const result = await this.uploadFile({
      file,
      employeeId: this.employee._id,
      category: this.category,
      period: this.period
    });
    if (result) {
      this.$emit('success', true);
    }
  }

  @paydocs.Action
  public uploadFile!: (data: {
    file: any;
    employeeId: string;
    category: string;
    period: Date;
  }) => boolean;
}
