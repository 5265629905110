


































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PLRSummary from '../types/paid-leave-rights.type';

const timeOff = namespace('timeOff');

@Component({
  name: 'PaidLeaveRights',
  components: {}
})
export default class PaidLeaveRights extends Vue {
  @timeOff.State
  public plrSummaries!: PLRSummary[];

  private async created(): Promise<void> {
    await this.getPLRSummariesFromRemote();
  }

  @timeOff.Action
  public getPLRSummariesFromRemote!: () => Promise<void>;
}
