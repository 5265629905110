




















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Employee } from '../types/employee.type';

const employees = namespace('employees');

@Component({
  name: 'SelectEmployee'
})
export default class SelectEmployee extends Vue {
  @employees.State
  public employees!: Employee;

  @Prop() employee!: string;

  private async created() {
    await this.getMyEmployeesFromRemote();
  }

  @employees.Action
  public getMyEmployeesFromRemote!: () => Promise<void>;
}
