




















import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';

@Component({
  name: 'UpdateEmployee',
  components: { Modal }
})
export default class UpdateEmployee extends Vue {
  @Prop({ default: false })
  public showNotification!: boolean;
}
