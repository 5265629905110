

















































































































































































































































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Request from './../types/request.type';
import PaidLeaveRequest from './../components/paid-leave-request.vue';
import PayrollIssuesRequest from './../components/payroll-issues-request.vue';
import HomeOfficeRequest from './../components/home-office-request.vue';
import { User } from '@/modules/settings/types/user.type';

const requests = namespace('requests');
const application = namespace('application');
const profile = namespace('profile');

@Component({
  name: 'Requests',
  components: {
    Header,
    PaidLeaveRequest,
    PayrollIssuesRequest,
    HomeOfficeRequest
  }
})
export default class Requests extends Vue {
  @profile.State
  public profile!: User;

  public requestSelected = {};
  public drawer = false;
  public activeTab = 0;
  public isProcessed = false;
  public dialogPaidLeaveRequest = false;
  public search = '';

  public currentHeaders = [
    { text: 'Category', value: 'category' },
    { text: 'Type', value: 'type' },
    { text: 'Creation Date', value: 'creationDate' },
    { text: 'Description', value: 'date' },
    { text: 'Status', value: 'status' },
    { text: '', value: 'actions', sortable: false }
  ];

  public closedHeaders = [
    { text: 'Category', value: 'category' },
    { text: 'Type', value: 'type' },
    { text: 'Creation Date', value: 'creationDate' },
    { text: 'Description', value: 'date' },
    { text: 'Status', value: 'status' },
    { text: '', value: 'actions', sortable: false }
  ];

  @requests.State
  public requests!: Request[];

  private async created(): Promise<void> {
    this.setIsNavigation(true);
    this.setTitle('Request(s)');
    await this.getMyRequestsFromRemote();
  }

  public filterRequests(
    value: unknown,
    search: string,
    item: Request
  ): boolean {
    switch (this.activeTab) {
      case 1:
        return item.processed === false;
      case 2:
        return item.processed === true;
      default:
        return true;
    }
  }

  public onAll(): void {
    this.activeTab = 0;
    this.search = '*';
  }
  public onCurrent(): void {
    this.activeTab = 1;
  }
  public onClosed(): void {
    this.activeTab = 2;
  }

  public async onDelete(_id: string): Promise<void> {
    this.removeRequestFromRemote(_id);
  }

  public onRequest(request: Request) {
    this.requestSelected = request;
    this.drawer = true;
  }

  @requests.Action
  getMyRequestsFromRemote!: () => void;

  @requests.Action
  removeRequestFromRemote!: (_id: string) => void;

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;
}
