

































































import { Component, Prop, Vue } from 'vue-property-decorator';

export type Event = {
  name: string;
  start: Date;
  end: Date;
  color: string;
  timed: boolean;
};

@Component
export default class Calendar extends Vue {
  @Prop({ default: [] }) public events!: Event[];
  public type = 'month';
  public focus = '';
  public typeToLabel = {
    month: 'Month',
    week: 'Week',
    day: 'Day'
  };
  public weekdays = [1, 2, 3, 4, 5, 6, 0];

  mounted() {
    (this.$refs.calendar as Vue & { checkChange: () => void }).checkChange();
  }

  public showEvent(event: unknown): void {
    console.log(event);
  }

  public viewDay(event: unknown): void {
    console.log(event);
  }

  public getEventColor(event: Event) {
    return event.color;
  }

  public updateRange(event: unknown): void {
    console.log(event);
  }

  public setToday(): void {
    this.focus = '';
  }
  public prev(): void {
    (this.$refs.calendar as Vue & { prev: () => void }).prev();
  }
  public next(): void {
    (this.$refs.calendar as Vue & { next: () => void }).next();
  }
}
