































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Celebration from '../types/celebration.type';
import Value from '@/components/value.vue';

const overview = namespace('overview');

@Component({
  name: 'Celebrations',
  components: { Value }
})
export default class Celebrations extends Vue {
  @overview.State
  nextCelebrations!: Celebration[];

  public indexCelebration = 0;

  private async created(): Promise<void> {
    await this.getNextCelebrations();
  }

  @overview.Action
  getNextCelebrations!: () => Promise<void>;
}
