






















































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Payslip } from '../types/payslip.type';

const paydocs = namespace('paydocs');

@Component({
  name: 'PaydocsRequests'
})
export default class PaydocsRequests extends Vue {
  @paydocs.State
  public paydocsRequests!: Payslip[];
  public search = '';
  public searchDisplay = '*';
  public employee = {};
  public activeTabCategory = 0;
  public activeTabStatus = 0;
  public headers = [
    {
      text: 'Employee',
      sortable: true,
      value: 'employee'
    },
    {
      text: 'Fullname',
      sortable: true,
      value: 'fullName'
    },
    {
      text: 'Category',
      value: 'category'
    },
    {
      text: 'Period',
      value: 'period'
    },
    {
      text: 'Date',
      value: 'creationDate'
    },
    {
      text: 'Status',
      value: 'delivred'
    },
    {
      text: '',
      value: 'actions'
    }
  ];

  private async created(): Promise<void> {
    await this.getPaydocsRequestsForMeFromRemote();
  }

  public onAllCat(): void {
    this.activeTabCategory = 0;
    this.searchDisplay = '*';
  }

  public onPayslip(): void {
    this.activeTabCategory = 1;
    this.searchDisplay = '*';
  }

  public onTax(): void {
    this.activeTabCategory = 2;
    this.searchDisplay = '*';
  }

  public onPayroll(): void {
    console.log('hey');
    this.activeTabStatus = 4;
    this.searchDisplay = '*';
  }

  public onOther(): void {
    this.activeTabCategory = 3;
    this.searchDisplay = '*';
  }

  public onAllStatus(): void {
    this.activeTabStatus = 0;
    this.searchDisplay = '*';
  }

  public onCurrent(): void {
    this.activeTabStatus = 1;
    this.searchDisplay = '*';
  }

  public onDelivred(): void {
    this.activeTabStatus = 2;
    this.searchDisplay = '*';
  }

  public onRequest(request: Payslip): void {
    this.$emit('request', request);
  }

  public filterRequests(value: unknown, search: string, item: Payslip) {
    switch (this.activeTabCategory) {
      case 0:
        switch (this.activeTabStatus) {
          case 0:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1)))
              );
            }
            return true;
          case 1:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                item.delivred !== true
              );
            }
            return item.delivred !== true;
          case 2:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                item.delivred === true
              );
            }
            return item.delivred === true;
          default:
            return true;
        }
      case 1:
        switch (this.activeTabStatus) {
          case 0:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                item.category === 'Payslip'
              );
            }
            return item.category === 'Payslip';
          case 1:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                item.category === 'Payslip' &&
                item.delivred !== true
              );
            }
            return item.category === 'Payslip' && item.delivred !== true;
          case 2:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                item.category === 'Payslip' &&
                item.delivred === true
              );
            }
            return item.category === 'Payslip' && item.delivred === true;
          default:
            return item.category === 'Payslip';
        }
      case 2:
        switch (this.activeTabStatus) {
          case 0:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                item.category === 'Tax'
              );
            }
            return item.category === 'Tax';
          case 1:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                item.category === 'Tax' &&
                item.delivred !== true
              );
            }
            return item.category === 'Tax' && item.delivred !== true;
          case 2:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                item.category === 'Tax' &&
                item.delivred === true
              );
            }
            return item.category === 'Tax' && item.delivred === true;
          default:
            return item.category === 'Tax';
        }
      case 3:
        switch (this.activeTabStatus) {
          case 0:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                item.category === 'Other'
              );
            }
            return item.category === 'Other';
          case 1:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                item.category === 'Other' &&
                item.delivred !== true
              );
            }
            return item.category === 'Other' && item.delivred !== true;
          case 2:
            if (this.search.length) {
              return (
                value != null &&
                this.search != null &&
                typeof value === 'string' &&
                (value
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  (item.employee &&
                    (item.employee.firstName
                      .toString()
                      .toLocaleUpperCase()
                      .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                      item.employee.lastName
                        .toString()
                        .toLocaleUpperCase()
                        .indexOf(this.search.toLocaleUpperCase()) !== -1))) &&
                item.category === 'Other' &&
                item.delivred === true
              );
            }
            return item.category === 'Other' && item.delivred === true;
          default:
            return item.category === 'Other';
        }
      default:
        if (this.search.length) {
          return (
            value != null &&
            this.search != null &&
            typeof value === 'string' &&
            (value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
              (item.employee &&
                (item.employee.firstName
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  item.employee.lastName
                    .toString()
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)))
          );
        }
        return true;
    }
  }
  @paydocs.Action
  private getPaydocsRequestsForMeFromRemote!: () => Promise<void>;
}
