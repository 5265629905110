









































































































































































































































import { Team } from '@/modules/settings/types/team.type';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Employee as EmployeeType } from './../types/employee.type';
import UpdateEmployeeFirstNameAndLastName from './update-employee-firstname-and-lastname.vue';
import UpdateEmployeeBirthdate from './update-employee-birthdate.vue';
import UpdateEmployeeHiredate from './update-employee-hiredate.vue';
import UpdateEmployeeJobTitle from './update-employee-job-title.vue';
import UpdateEmployeeMail from './update-employee-mail.vue';
import UpdateEmployeePhone from './update-employee-phone.vue';
import UpdateEmployeeTeam from './update-employee-team.vue';
import UpdateEmployeeNationalId from './update-employee-national-id.vue';
import UpdateEmployeeFamilySituation from './update-employee-family-situation.vue';
import UpdateEmployeeContractType from './update-employee-contract-type.vue';
import UpdateAvatar from '@/components/update-avatar.vue';

const employees = namespace('employees');

@Component({
  name: 'Employee',
  components: {
    UpdateEmployeeFirstNameAndLastName,
    UpdateEmployeeBirthdate,
    UpdateEmployeeHiredate,
    UpdateEmployeeJobTitle,
    UpdateEmployeeMail,
    UpdateEmployeePhone,
    UpdateEmployeeTeam,
    UpdateEmployeeNationalId,
    UpdateEmployeeFamilySituation,
    UpdateEmployeeContractType,
    UpdateAvatar
  }
})
export default class Employee extends Vue {
  @Prop() employee!: EmployeeType;

  public showNotification = false;

  public resetDialog(): void {
    this.showNotification = false;
  }

  public async updateEmployeeFirstNameAndLastName(): Promise<void> {
    const isUpdated = await this.updateEmployee({
      _id: this.employee._id,
      firstName: this.employee.firstName,
      lastName: this.employee.lastName
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public async updateContractType(): Promise<void> {
    const isUpdated = await this.updateEmployee({
      _id: this.employee._id,
      contractType: this.employee.contractType
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }
  public async updateHireDate(): Promise<void> {
    const isUpdated = await this.updateEmployee({
      _id: this.employee._id,
      dateOfHire: this.employee.dateOfHire
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public selectHireDate(dateOfHire: Date): void {
    this.setEmployee({ ...this.employee, dateOfHire });
  }

  public selectContractType(contractType: string): void {
    this.setEmployee({ ...this.employee, contractType });
  }

  public selectFamilySituation(familySituation: string): void {
    this.setEmployee({ ...this.employee, familySituation });
  }

  public async updateBirthdate(): Promise<void> {
    const isUpdated = await this.updateEmployee({
      _id: this.employee._id,
      birthdate: this.employee.birthdate
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public selectBirthdate(birthdate: Date): void {
    this.setEmployee({ ...this.employee, birthdate });
  }

  public async updateJobTitle(): Promise<void> {
    const isUpdated = await this.updateEmployee({
      _id: this.employee._id,
      jobTitle: this.employee.jobTitle
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public async updateFamilySituation(): Promise<void> {
    const isUpdated = await this.updateEmployee({
      _id: this.employee._id,
      familySituation: this.employee.familySituation
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public async updateNationalId(): Promise<void> {
    const isUpdated = await this.updateEmployee({
      _id: this.employee._id,
      nId: this.employee.nId
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }
  public async updatePhone(): Promise<void> {
    const isUpdated = await this.updateEmployee({
      _id: this.employee._id,
      phone: this.employee.phone
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public async updateMail(): Promise<void> {
    const isUpdated = await this.updateEmployee({
      _id: this.employee._id,
      mail: this.employee.mail
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public async updateTeam(): Promise<void> {
    const isUpdated = await this.updateEmployee({
      _id: this.employee._id,
      teamId: this.employee.teamId
    });
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  public selectTeam(team: Team): void {
    this.setEmployee({ ...this.employee, teamId: team._id, team });
  }

  public async cancelUpdate(): Promise<void> {
    await this.getEmployeeFromRemote(this.employee._id);
  }

  public async changeAvatar(avatar: File) {
    await this.updateAvatar({ avatar, _id: this.employee._id });
  }

  @employees.Mutation
  setEmployee!: (employee: EmployeeType) => void;

  @employees.Action
  updateEmployee!: (employee: EmployeeType) => Promise<boolean>;

  @employees.Action
  getEmployeeFromRemote!: (_id: string) => Promise<void>;

  @employees.Action
  updateAvatar!: (updateAvatarInput: {
    avatar: File;
    _id: string;
  }) => Promise<void>;
}
