import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { CreateRole } from '../types/create-role.type';
import { Role } from '../types/role.type';
import { Service } from '../types/service.type';

@Module({ namespaced: true })
export default class Roles extends VuexModule {
  public services: Service[] = [];
  public models: Role[] = [];
  public newRole: CreateRole = {
    fullName: '',
    description: ''
  };

  public role: Role = {
    _id: '',
    fullName: '',
    description: ''
  };

  public roles: Role[] = [];

  @Mutation
  public setRole(role: Role): void {
    this.role = role;
  }

  @Mutation
  public setModels(models: Role[]): void {
    this.models = models;
  }

  @Mutation
  public setServices(services: Service[]): void {
    this.services = services;
  }

  @Mutation
  public clearNewRole(): void {
    this.newRole = {
      fullName: '',
      description: ''
    };
  }

  @Mutation
  public setRoles(roles: Role[]): void {
    this.roles = roles;
  }

  @Action({ rawError: true })
  public async getRolesFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Role {
          findAllRoles {
            _id
            fullName
            description
            isPaydocs
            isPeople
            isPayroll
            isRequests
            isSettings
            isTimeOff
            isTimeOffManager
            isEmployee
            isPaydocsManager
            isRequestsManager
            isManager
            services {
              _id
              fullName
              description
              level
              prerequisites
              prerequisitesId
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.findAllRoles)
      this.context.commit('setRoles', result.data.findAllRoles);
  }

  @Action
  public async createRole(): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const { newRole } = this;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Role($createRoleInput: CreateRoleInput!) {
          createRole(createRoleInput: $createRoleInput) {
            _id
            fullName
            description
          }
        }
      `,
      variables: { createRoleInput: newRole },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.createRole) {
      this.context.commit('clearNewRole');
      await this.context.dispatch('getRolesFromRemote');
      return true;
    }
    return false;
  }

  @Action
  public async updateRole(role: Role) {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Role($updateRoleInput: UpdateRoleInput!) {
          updateRole(updateRoleInput: $updateRoleInput)
        }
      `,
      variables: { updateRoleInput: role },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.updateRole) {
      this.context.dispatch('getRolesFromRemote');
      return true;
    }
    return false;
  }

  @Action
  public async deleteRoleFromRemote(_id: string): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Role($_id: String!) {
          deleteRole(_id: $_id)
        }
      `,
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.deleteRole) {
      this.context.dispatch('getRolesFromRemote');
      return true;
    }
    return false;
  }

  @Action({ rawError: true })
  public async getServices(): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Role {
          getServices {
            _id
            fullName
            description
            level
            prerequisites
            prerequisitesId
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getServices) {
      this.context.commit('setServices', result.data.getServices);
      return true;
    }
    return false;
  }

  @Action({ rawError: true })
  public async getModels(): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Role {
          getModels {
            _id
            fullName
            services {
              _id
              fullName
              description
              level
              prerequisitesId
              prerequisites
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getModels) {
      this.context.commit('setModels', result.data.getModels);
      return true;
    }
    return false;
  }
}
