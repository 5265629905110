





















import { Vue, Prop, Component } from 'vue-property-decorator';

interface Item {
  date: string;
  avatar: string;
  title: string;
  subtitle: string;
}

@Component({})
export default class TimelineItem extends Vue {
  @Prop() private item!: Item;
}
