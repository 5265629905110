






















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import Value from '@/components/value.vue';
import { User } from '../../types/user.type';

const users = namespace('users');

@Component({
  name: 'DeleteUser',
  components: { Modal, Value }
})
export default class DeleteUser extends Vue {
  @Prop() user!: User;

  public showNotification = false;

  public async deleteUser(userId: string): Promise<void> {
    const isDeleted = await this.deleteUserFromRemote(userId);
    if (isDeleted) {
      this.showNotification = true;
    }
  }

  @users.Action
  public deleteUserFromRemote!: (_id: string) => Promise<boolean>;
}
