




























import { Employee } from '@/modules/employees/types/employee.type';
import { Component, Vue } from 'vue-property-decorator';
import SelectEmployee from './select-employee.vue';
import { namespace } from 'vuex-class';
import { Payslip } from '../types/payslip.type';

const paydocs = namespace('paydocs');

@Component({
  name: 'PaydocsTracker',
  components: { SelectEmployee }
})
export default class PaydocsTracker extends Vue {
  @paydocs.State
  public paydocs!: Payslip[];

  public employee = {};
  public headers = [
    {
      text: 'Employee',
      sortable: true,
      value: 'fullName'
    },
    {
      text: 'Category',
      value: 'category'
    },
    {
      text: 'Period',
      value: 'period'
    },
    {
      text: 'Date',
      value: 'creationDate'
    }
  ];

  public async setEmployee(employee: Employee) {
    this.employee = { ...employee };
    await this.getPaydocsFromRemote(employee._id);
  }

  @paydocs.Action
  private getPaydocsFromRemote!: (_id: string) => Promise<void>;
}
