























import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
import { Employee } from '../types/employee.type';

@Component({
  name: 'UpdateEmployeeFamilySituation',
  components: { UpdateEmployee }
})
export default class UpdateEmployeeFamilySituation extends Vue {
  @Prop() employee!: Employee;

  @Prop({ default: false }) showNotification!: boolean;

  public familySituation = ['Married', 'Unmarried'];
}
