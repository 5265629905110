



























import { Employee } from '@/modules/settings/types/employee.type';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Balance',
  components: {}
})
export default class Balance extends Vue {
  @Prop() employees!: Employee[];

  public headers = [
    { text: 'Firstname', value: 'firstName' },
    { text: 'Lastname', value: 'lastName' },
    { text: 'Days', value: 'timeOffBalance' }
  ];

  public search = '';
}
