






















































































import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Team as TeamT } from '../types/team.type';
import TeamList from './team/team-list.vue';
import NewTeam from './team/new-team.vue';
import Team from './team/team.vue';

const teams = namespace('teams');
const application = namespace('application');

@Component({
  name: 'Teams',
  components: { Header, TeamList, NewTeam, Team }
})
export default class Teams extends Vue {
  @Prop({ default: false }) public onlyDataTable!: boolean;

  @Prop({ default: 'Teams' }) public title!: string;

  @teams.State
  public team!: TeamT;

  @teams.State
  public teams!: TeamT[];

  public search = '';

  public displayTeams: TeamT[] = [];

  public isList = 0;

  public drawer = false;

  public headers = [
    {
      text: 'Fullname',
      value: 'fullName'
    },
    {
      text: 'Leader',
      value: 'leader'
    },
    { text: 'Description', value: 'description' },
    { text: '', value: 'actions' }
  ];

  private async created() {
    this.setTitle('Teams Settings');
    await this.getTeamsFromRemote();
    this.displayTeams = this.teams;
  }

  public onTeam(team: TeamT) {
    this.$emit('team', team);
    this.setTeam(team);
    this.drawer = true;
  }

  private openDrawer(): void {
    this.drawer = true;
  }

  public handleTeam(team: TeamT) {
    this.setTeam(team);
    this.openDrawer();
  }

  public onList() {
    this.isList = 0;
  }

  public onCard() {
    this.isList = 1;
  }

  public searchByName(args: string) {
    this.search = args;
    this.displayTeams = this.teams.filter(team => {
      return (
        team != null &&
        ((team.fullName &&
          typeof args === 'string' &&
          team.fullName
            .toLocaleUpperCase()
            .indexOf(args.toLocaleUpperCase()) !== -1) ||
          (team.description &&
            typeof args === 'string' &&
            team.description
              .toLocaleUpperCase()
              .indexOf(args.toLocaleUpperCase()) !== -1) ||
          (team.leader &&
            team.leader.firstName &&
            typeof args === 'string' &&
            team.leader.firstName
              .toLocaleUpperCase()
              .indexOf(args.toLocaleUpperCase()) !== -1) ||
          (team.leader &&
            team.leader.lastName &&
            typeof args === 'string' &&
            team.leader.lastName
              .toLocaleUpperCase()
              .indexOf(args.toLocaleUpperCase()) !== -1))
      );
    });
  }

  @teams.Mutation
  private setTeam!: (team: TeamT) => void;

  @teams.Action
  public getTeamsFromRemote!: () => Promise<void>;

  @application.Mutation
  setTitle!: (title: string) => void;
}
