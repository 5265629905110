


















































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const login = namespace('login');

const application = namespace('application');

@Component({
  name: 'ResetPassword'
})
export default class ResetPassword extends Vue {
  @application.State
  public logoPath!: string;

  public valid = false;
  public loading = false;
  public password = '';
  public confirmPassword = '';
  public showPassword = false;
  public showConfirmPassword = false;
  public isPasswordUpdated = false;

  public passwordRules = [
    (value: string) =>
      (!!value && value.length > 7) || 'Un minimum de 8 caractères'
  ];

  created() {
    this.setIsNavigation(false);
  }

  private async onContinue() {
    this.loading = true;
    if (this.isPasswordUpdated) {
      this.$router.push('/login');
    } else {
      const token = this.$route.params.token;
      this.isPasswordUpdated = await this.updatePassword({
        password: this.password,
        token
      });
    }
    this.loading = false;
  }

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @login.Action
  private updatePassword!: ({
    password,
    token
  }: {
    password: string;
    token: string;
  }) => Promise<boolean>;
}
