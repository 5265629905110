




















































































































































































































































































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TimeOff from '../types/time-off.type';
import RequestTimeOff from '../components/request-time-off.vue';
import { User } from '@/modules/settings/types/user.type';

const timeOff = namespace('timeOff');
const application = namespace('application');
const profile = namespace('profile');

@Component({
  name: 'TimeOff',
  components: { Header, RequestTimeOff }
})
export default class TimeOffRequests extends Vue {
  @profile.State
  public profile!: User;

  public drawer = false;

  public headers = [
    {
      text: 'Effective date',
      align: 'start',
      value: 'startDate'
    },
    { text: 'Day(s)', value: 'value' },
    { text: 'Type', value: 'type' },
    { text: 'Creation Date', value: 'creationDate' },
    { text: 'Note', value: 'note' },
    { text: 'Status', value: 'status' },
    { text: '', value: 'actions', sortable: false }
  ];

  public timeOffSelected: TimeOff = {
    _id: '',
    employeeId: '',
    fullName: '',
    type: '',
    creationDate: new Date(),
    value: 0,
    status: '',
    processed: false
  };

  @timeOff.State
  public timeOff!: TimeOff[];

  @timeOff.State
  public timeOffBalance!: number;

  private async created(): Promise<void> {
    this.setIsNavigation(true);
    this.setTitle('Time off');
    await this.getMyTimeOffFromRemote();
    await this.myTimeOffBalanceFromRemote();
  }

  public async onDelete(_id: string): Promise<void> {
    await this.removeTimeOffFromRemote(_id);
  }

  public onTimeOff(timeOffSelected: TimeOff) {
    this.drawer = true;
    this.timeOffSelected = timeOffSelected;
  }

  @timeOff.Action
  private getMyTimeOffFromRemote!: () => Promise<void>;

  @timeOff.Action
  private myTimeOffBalanceFromRemote!: () => Promise<void>;

  @timeOff.Action
  private removeTimeOffFromRemote!: (_id: string) => Promise<void>;

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;
}
