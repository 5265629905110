

















































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Request from './../types/request.type';
import Modal from '@/components/modal.vue';
import CreateRequest from '../types/create-request.type';
import SelectPeriod from '@/components/select-period.vue';
import Label from '@/components/label.vue';

const requests = namespace('requests');
const timeOff = namespace('timeOff');

@Component({
  name: 'PaidLeaveRequest',
  components: { Header, Modal, SelectPeriod, Label }
})
export default class PaidLeaveRequest extends Vue {
  public firstDate = null;
  public firstDateMenu = false;
  public lastDate = null;
  public lastDateMenu = false;
  public loading = false;
  public showNotification = false;

  @requests.State
  public newRequest!: CreateRequest;

  @timeOff.State
  private timeOffBalance!: number;

  private created() {
    this.clearNewRequest();
    this.myTimeOffBalanceFromRemote();
  }

  public setStartDate(startDate: Date) {
    this.setNewRequest({ ...this.newRequest, startDate });
  }

  public setEndDate(endDate: Date) {
    this.setNewRequest({ ...this.newRequest, endDate });
  }

  public closeDialog() {
    this.showNotification = false;
    this.loading = false;
    this.clearNewRequest();
  }

  public open() {
    this.setCategory('Paid Leave');
    this.setType('Balance Adjustment');
  }

  public async save() {
    /*  this.showNotification = true; */
    this.loading = true;
    const result = await this.createRequest();
    if (result) {
      this.showNotification = true;
    }
    this.loading = false;
  }

  @requests.Mutation
  clearNewRequest!: () => void;

  @requests.Mutation
  setNewRequest!: (newRequest: CreateRequest) => void;

  @requests.Mutation
  setCategory!: (category: string) => void;

  @requests.Mutation
  setType!: (type: string) => void;

  @requests.Action
  createRequest!: () => boolean;

  @timeOff.Action
  private myTimeOffBalanceFromRemote!: () => void;
}
