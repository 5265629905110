











































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Label from '@/components/label.vue';

@Component({ name: 'SelectPeriod', components: { Label } })
export default class SelectPeriod extends Vue {
  public valid = false;
  public startAM = true;
  public startPM = true;
  public endAM = true;
  public endPM = true;
  public firstDateMenu = false;
  public lastDateMenu = false;
  public startDateDisplay: Date | string = '';

  public endDateDisplay: Date | string = '';

  public checkboxRules = [
    () =>
      (this.startDateDisplay === this.endDateDisplay &&
        this.startAM !== this.endPM) ||
      'One must be checked'
  ];

  get showStartPM() {
    return (
      this.startDateDisplay && this.startDateDisplay !== this.endDateDisplay
    );
  }

  get showEndAM() {
    return this.endDateDisplay && this.startDateDisplay !== this.endDateDisplay;
  }

  public handleDate() {
    if (
      this.startDateDisplay &&
      this.endDateDisplay &&
      this.endDateDisplay === this.startDateDisplay
    ) {
      if (this.startAM && !this.endPM) {
        const start = new Date(this.startDateDisplay);
        const end = new Date(this.endDateDisplay);
        start.setHours(8);
        end.setHours(12);
        this.$emit('start', start);
        this.$emit('end', end);
      } else if (!this.startAM && this.endPM) {
        const start = new Date(this.startDateDisplay);
        const end = new Date(this.endDateDisplay);
        start.setHours(13);
        end.setHours(17);
        this.$emit('start', start);
        this.$emit('end', end);
      } else {
        const start = new Date(this.startDateDisplay);
        const end = new Date(this.endDateDisplay);
        start.setHours(8);
        end.setHours(17);
        this.$emit('start', start);
        this.$emit('end', end);
      }
    } else if (
      this.startDateDisplay &&
      this.endDateDisplay &&
      this.endDateDisplay !== this.startDateDisplay
    ) {
      if (this.startAM && this.endPM) {
        const start = new Date(this.startDateDisplay);
        const end = new Date(this.endDateDisplay);
        start.setHours(8);
        end.setHours(17);
        this.$emit('start', start);
        this.$emit('end', end);
      }
      if (this.startAM && !this.endPM) {
        const start = new Date(this.startDateDisplay);
        const end = new Date(this.endDateDisplay);
        start.setHours(8);
        end.setHours(12);
        this.$emit('start', start);
        this.$emit('end', end);
      } else if (!this.startAM && this.endPM) {
        const start = new Date(this.startDateDisplay);
        const end = new Date(this.endDateDisplay);
        start.setHours(13);
        end.setHours(17);
        this.$emit('start', start);
        this.$emit('end', end);
      } else if (!this.startAM && !this.endPM) {
        const start = new Date(this.startDateDisplay);
        const end = new Date(this.endDateDisplay);
        start.setHours(13);
        end.setHours(12);
        this.$emit('start', start);
        this.$emit('end', end);
      }
    } else if (this.startDateDisplay && this.startAM) {
      const start = new Date(this.startDateDisplay);
      start.setHours(8);
      this.$emit('start', start);
    } else if (this.startDateDisplay && !this.startAM) {
      const start = new Date(this.startDateDisplay);
      start.setHours(13);
      this.$emit('start', start);
    } else if (this.endDateDisplay && this.endPM) {
      const end = new Date(this.endDateDisplay);
      end.setHours(17);
      this.$emit('end', end);
    } else if (this.endDateDisplay && !this.endPM) {
      const end = new Date(this.endDateDisplay);
      end.setHours(12);
      this.$emit('end', end);
    }
  }
}
