





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User } from '../../types/user.type';

const users = namespace('users');

const managers = namespace('managers');

@Component({
  name: 'SelectUser'
})
export default class SelectUser extends Vue {
  @users.State
  public users!: User[];

  @Prop() user!: User;

  @Prop({ default: 'User' }) label!: string;

  @Prop({ default: false }) manager!: boolean;

  private async created() {
    await this.loadData();
  }

  private async updated() {
    await this.loadData();
  }

  private async loadData() {
    if (this.manager) {
      this.getManagersFromRemote();
    } else {
      await this.getUsersFromRemote();
    }
  }

  @managers.Action
  public getManagersFromRemote!: () => void;

  @users.Action
  public getUsersFromRemote!: () => void;
}
