var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-navigation-drawer',{attrs:{"temporary":"","absolute":"","right":"","width":"70%"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('Employee',{attrs:{"employee":_vm.employee}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-none d-sm-flex",attrs:{"cols":"auto"}},[_c('NewEmployee')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.isList === 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.displayEmployees,"items-per-page":5,"sort-by":"firstName"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"table__header",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","hide-details":"auto","single-line":"","prepend-icon":"mdi-magnify"},on:{"input":_vm.searchByName}}),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn-toggle',{attrs:{"borderless":"","color":"primary"},model:{value:(_vm.isTerminated),callback:function ($$v) {_vm.isTerminated=$$v},expression:"isTerminated"}},[_c('v-btn',{staticClass:"employees__btn__label",attrs:{"borderless":""},on:{"click":_vm.onPayroll}},[_vm._v(" On payroll ")]),_c('v-btn',{staticClass:"employees__btn__label",attrs:{"borderless":""},on:{"click":_vm.onTerminated}},[_vm._v(" Terminated ")])],1)],1),_c('v-col',{staticClass:"d-none d-sm-flex",attrs:{"cols":"auto"}},[_c('v-btn-toggle',{attrs:{"borderless":"","color":"primary"},model:{value:(_vm.isList),callback:function ($$v) {_vm.isList=$$v},expression:"isList"}},[_c('v-btn',{attrs:{"borderless":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-view-list ")])],1),_c('v-btn',{attrs:{"borderless":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-view-column ")])],1)],1)],1)],1)]},proxy:true},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"employee--avatar",attrs:{"color":"primary","size":"50"}},[_c('v-img',{attrs:{"src":item.avatar}})],1)]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.lastName",fn:function(ref){return undefined}},{key:"item.team",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.team ? item.team.fullName : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.showItem(item)}}},[_vm._v(" Show Details ")])]}}],null,false,15357648)}):_vm._e(),(_vm.isList === 1)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Search","hide-details":"auto","single-line":"","prepend-icon":"mdi-magnify"},on:{"input":_vm.searchByName}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn-toggle',{attrs:{"borderless":"","color":"primary"},model:{value:(_vm.isTerminated),callback:function ($$v) {_vm.isTerminated=$$v},expression:"isTerminated"}},[_c('v-btn',{staticClass:"employees__btn__label",attrs:{"borderless":""},on:{"click":_vm.onPayroll}},[_vm._v(" On payroll ")]),_c('v-btn',{staticClass:"employees__btn__label",attrs:{"borderless":""},on:{"click":_vm.onTerminated}},[_vm._v(" Terminated ")])],1)],1),_c('v-col',{staticClass:"d-none d-sm-flex",attrs:{"cols":"auto"}},[_c('v-btn-toggle',{attrs:{"borderless":"","color":"primary"},model:{value:(_vm.isList),callback:function ($$v) {_vm.isList=$$v},expression:"isList"}},[_c('v-btn',{attrs:{"borderless":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-view-list ")])],1),_c('v-btn',{attrs:{"borderless":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-view-column ")])],1)],1)],1)],1):_vm._e(),(_vm.isList === 1)?_c('EmployeeList',{attrs:{"employees":_vm.displayEmployees},on:{"employee":_vm.showItem}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }