import auth from '@/guards/auth.guard';
import { RouteConfig } from 'vue-router';
import Overview from './../views/Overview.vue';
import OverviewManager from './../views/OverviewManager.vue';

const overviewRoutes: Array<RouteConfig> = [
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/manager/overview',
    name: 'OverviewManager',
    component: OverviewManager,
    meta: {
      middleware: [auth]
    }
  }
];

export default overviewRoutes;
