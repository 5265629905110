























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import { CreateUser } from '../../types/create-user.type';
import SelectRole from '../role/select-role.vue';
import SelectTeam from '../team/select-team.vue';
import { Role } from '../../types/role.type';
import { Team } from '../../types/team.type';

const users = namespace('users');

@Component({
  name: 'NewUser',
  components: { Modal, SelectRole, SelectTeam }
})
export default class NewUser extends Vue {
  @users.State
  newUser!: CreateUser;

  public showNotification = false;

  public async save() {
    const result = await this.createUser();
    if (result) {
      this.showNotification = true;
    }
  }

  public closeDialog() {
    this.showNotification = false;
  }

  public selectRole(role: Role) {
    this.setNewUser({ ...this.newUser, roleId: role._id });
  }

  public selectTeam(team: Team) {
    this.setNewUser({ ...this.newUser, teamId: team._id });
  }

  @users.Mutation
  setNewUser!: (newUser: CreateUser) => void;

  @users.Action
  createUser!: () => boolean;
}
