






















import { Component, Vue, Prop } from 'vue-property-decorator';

interface Item {
  avatar: string;
  title: string;
  startDate: Date;
}

@Component({ name: 'EmployeeListItem' })
export default class EmployeeListItem extends Vue {
  @Prop() private item!: Item;
}
