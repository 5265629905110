

































import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
import SelectCategory from './select-category.vue';
import UploadPaydoc from './upload-paydoc.vue';
import SelectEmployee from './select-employee.vue';
import SelectPeriod from './select-period.vue';
import { Employee } from '@/modules/employees/types/employee.type';

@Component({
  name: 'AddDocument',
  components: {
    Header,
    Modal,
    SelectCategory,
    SelectEmployee,
    SelectPeriod,
    UploadPaydoc
  }
})
export default class AddDocument extends Vue {
  @Prop()
  public employee!: Employee;

  @Prop()
  public category!: string;

  @Prop({ default: '' }) public period!: Date | undefined;

  @Prop()
  public paydoc!: string;

  public showNotification = false;

  public closeDialog() {
    this.showNotification = false;
  }

  public async save() {
    /*     if (result) { */
    this.showNotification = true;
    /*    } */
  }

  public onSuccess(args: boolean): void {
    this.showNotification = true;
    this.$emit('success', args);
  }
}
