import auth from '@/guards/auth.guard';
import { RouteConfig } from 'vue-router';
import MyInfo from '../views/my-info.vue';

const myAccountRoutes: Array<RouteConfig> = [
  {
    path: '/myAccount',
    name: 'MyAccount',
    component: MyInfo,
    meta: {
      middleware: [auth]
    }
  }
];

export default myAccountRoutes;
