import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Employee } from '../types/employee.type';

@Module({ namespaced: true })
export default class Members extends VuexModule {
  public employees: Employee[] = [];
  public employeesNotTeam: Employee[] = [];

  @Mutation
  public setEmployees(employees: Employee[]): void {
    this.employees = employees;
  }

  @Mutation
  public setEmployeesNotTeam(employees: Employee[]): void {
    this.employeesNotTeam = employees;
  }

  @Action
  public async getEmployeesNotTeamFromRemote(teamId: string) {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Settings($_id: String!) {
          findAllEmployeesNotTeam(_id: $_id) {
            _id
            firstName
            lastName
          }
        }
      `,
      variables: { _id: teamId },
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.findAllEmployeesNotTeam)
      this.context.commit(
        'setEmployeesNotTeam',
        result.data.findAllEmployeesNotTeam
      );
  }

  @Action
  public async getEmployeesNotUserFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Settings {
          findEmployeesNotUser {
            _id
            firstName
            lastName
            user {
              firstName
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.findEmployeesNotUser)
      this.context.commit('setEmployees', result.data.findEmployeesNotUser);
  }
}
