














































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const login = namespace('login');

const application = namespace('application');

@Component({
  name: 'Login'
})
export default class Login extends Vue {
  @application.State
  public logoPath!: string;

  public valid = false;
  public error = false;
  public errorMessages: string[] = [];
  public userName = '';
  public password = '';
  public showPassword = false;

  public userNameRules = [
    (v: string) =>
      !v ||
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      'E-mail must be valid'
  ];

  public passwordRules = [
    (value: string) =>
      (!!value && value.length > 7) || 'Un minimum de 8 caractères'
  ];

  private created(): void {
    this.setIsNavigation(false);
    this.setTitle('');
  }

  public async onSave(): Promise<void> {
    const isLogedIn = await this.connectOnRemote();
    if (!isLogedIn) {
      this.error = true;
      this.errorMessages = ['Wrong username password combination'];
    }
  }

  public onNewAccount(): void {
    this.$router.push('/register');
  }

  public onForgotenPassword(): void {
    this.$router.push('/password/recover');
  }

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;

  @login.Action
  private saveUserName!: (userName: string) => void;

  @login.Action
  private savePassword!: (password: string) => void;

  @login.Action
  private connectOnRemote!: () => Promise<boolean>;
}
