























































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Register as RegisterType } from '../types/register.type';

const register = namespace('register');
const application = namespace('application');

@Component({
  name: 'Register'
})
export default class Register extends Vue {
  @register.State
  newUser!: RegisterType;

  @application.State
  public logoPath!: string;

  public dateOfHireMenu = false;
  public showPassword = false;
  public showConfirmPassword = false;
  public confirmPassword = '';
  public valid = false;

  private mailRules = [
    (v: string) =>
      !v ||
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      'E-mail must be valid'
  ];

  private passwordRules = [
    (value: string) =>
      (!!value && value.length > 7) || 'Un minimum de 8 caractères'
  ];

  private created(): void {
    this.setIsNavigation(false);
    this.setTitle('');
  }

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;

  @register.Mutation
  setNewUser!: (newUser: RegisterType) => void;

  @register.Action
  registerOnRemote!: () => boolean;
}
