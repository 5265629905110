















































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import CreateRequest from '../types/create-request.type';

const requests = namespace('requests');

@Component({
  name: 'PayrollIssuesRequest',
  components: { Header, Modal }
})
export default class PayrollIssuesRequest extends Vue {
  public showNotification = false;
  public loading = false;
  public types = ['Payslip', 'Payment', 'Tax', 'Other'];

  @requests.State
  public newRequest!: CreateRequest;

  private created() {
    this.clearNewRequest();
  }

  public async save() {
    this.loading = true;
    const result = await this.createRequest();
    if (result) {
      this.showNotification = true;
    }
    this.loading = false;
  }

  public open() {
    this.setCategory('Payroll');
  }

  public closeDialog() {
    this.showNotification = false;
    this.clearNewRequest();
    this.loading = false;
  }

  @requests.Mutation
  clearNewRequest!: () => void;

  @requests.Mutation
  setCategory!: (category: string) => void;

  @requests.Action
  createRequest!: () => boolean;
}
