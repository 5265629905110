


























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'SelectPeriod'
})
export default class SelectPeriod extends Vue {
  public period: Date | string = '';
  public menu = false;

  public onContinue(period: Date) {
    this.period = period;
    this.$emit('period', period);
  }
}
