






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectTeamNotUser from './select-team-not-user.vue';
import { Team } from '../../types/team.type';
import { User } from '../../types/user.type';
import { AddTeamMember as AddTeamMemberType } from '../../types/add-team-member.type';

const teams = namespace('teams');

@Component({
  name: 'AddTeam',
  components: { Modal, SelectTeamNotUser }
})
export default class AddTeam extends Vue {
  @Prop()
  public user!: User;

  public showNotification = false;

  public teamSelected: Team = {
    _id: '',
    fullName: ''
  };

  public setTeamSelected(teamSelected: Team) {
    this.teamSelected = {
      _id: teamSelected._id,
      fullName: teamSelected.fullName
    };
  }

  public async save() {
    if (this.user && this.user._id) {
      const result = await this.addTeamMember({
        teamId: this.teamSelected._id,
        memberId: this.user._id
      });
      if (result) {
        this.showNotification = true;
      }
    }
  }

  public closeDialog() {
    this.showNotification = false;
  }

  @teams.Action
  addTeamMember!: (addTeamMember: AddTeamMemberType) => boolean;
}
