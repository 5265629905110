





















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Notification from './notification.vue';

@Component({
  name: 'Modal',
  components: { Notification }
})
export default class Modal extends Vue {
  public dialog = false;

  @Prop({ type: Boolean, default: true })
  public cardText!: boolean;

  @Prop({ type: String, default: '700px' })
  public maxWidth!: string;

  @Prop({ type: Boolean, default: false })
  public fullscreen!: boolean;

  @Prop({ type: Boolean, default: false })
  public noWidth!: boolean;

  @Prop({ type: Boolean, default: false })
  public text!: boolean;

  @Prop({ type: String, default: '' })
  public modalTitle!: string;

  @Prop({ type: String, default: '' })
  public buttonIcon!: string;

  @Prop({ type: String, default: '' })
  public buttonIconSize!: string;

  @Prop({ type: Boolean, default: false })
  public fabButton!: boolean;

  @Prop({ type: String, default: '' })
  public buttonTitle!: string;

  @Prop({ type: Boolean, default: true })
  public showSlot!: boolean;

  @Prop({ type: Boolean, default: false })
  public showNotification!: boolean;

  @Prop({ type: String, default: '' })
  public notificationTitle!: string;

  @Prop({ type: String, default: '' })
  public notificationMessage!: string;

  @Prop({ type: Boolean, default: false })
  public showSave!: boolean;

  @Prop({ type: Boolean, default: false })
  public saveIsDisabled!: boolean;

  @Prop({ type: Boolean, default: false })
  public showPrevious!: boolean;

  @Prop({ type: Boolean, default: false })
  public previousIsDisabled!: boolean;

  @Prop({ type: Boolean, default: false })
  public showNext!: boolean;

  @Prop({ type: Boolean, default: false })
  public nextIsDisabled!: boolean;

  @Prop({ type: String, default: '' })
  public notificationIcon!: string;

  @Prop({ type: String, default: 'primary' })
  public colorButton!: string;

  @Prop({ type: String, default: '' })
  public textColorButton!: string;

  close() {
    this.dialog = false;
    this.$emit('close');
  }

  cancel() {
    this.$emit('cancel');
    this.close();
  }

  public onSave() {
    this.$emit('save');
  }

  public onPrevious() {
    this.$emit('previous');
  }

  public onNext() {
    this.$emit('next');
  }
}
