








import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '../../types/user.type';
import UserListItem from './user-list-item.vue';

@Component({
  name: 'UserList',
  components: {
    UserListItem
  }
})
export default class UserList extends Vue {
  @Prop() users!: User[];
}
