





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Role } from '../../types/role.type';
import UpdateRole from './update-role.vue';
import { namespace } from 'vuex-class';
import { Service } from '../../types/service.type';

const roles = namespace('roles');

@Component({
  name: 'UpdateServices',
  components: { UpdateRole }
})
export default class UpdateServices extends Vue {
  @Prop() role!: Role;

  @Prop({ default: false }) showNotification!: boolean;

  @roles.State
  public services!: Service[];

  @roles.State
  public models!: Role[];

  public selectedServices: Service[] = [];

  public model = '';
  public search = '';
  public headers = [
    {
      text: 'Fullname',
      align: 'start',
      value: 'fullName'
    },
    {
      text: 'Level',
      align: 'start',
      value: 'level'
    },
    {
      text: 'Description',
      align: 'start',
      value: 'description'
    }
  ];

  private async created() {
    if (this.role && this.role.services) {
      this.selectedServices = this.role.services;
    }

    await this.getServices();
    await this.getModels();
  }

  public selectModel(model: Role): void {
    console.log('model', model);
    if (model && model.services) {
      this.selectedServices = model.services;
    }
  }

  @roles.Action
  getServices!: () => void;

  @roles.Action
  getModels!: () => void;
}
