


























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectEmployeeNotTeam from './select-employee-not-team.vue';
import { Team } from '../../types/team.type';
import { User } from '../../types/user.type';
import { AddTeamMember as AddTeamMemberType } from '../../types/add-team-member.type';

const teams = namespace('teams');

@Component({
  name: 'AddTeamMember',
  components: { Modal, SelectEmployeeNotTeam }
})
export default class AddTeamMember extends Vue {
  @teams.State
  public team!: Team;

  public showNotification = false;

  public userSelected: User = {
    _id: '',
    firstName: '',
    lastName: ''
  };

  public setUserSelected(userSelected: User) {
    this.userSelected = {
      _id: userSelected._id,
      firstName: userSelected.firstName,
      lastName: userSelected.lastName
    };
  }

  public async save() {
    if (this.userSelected && this.userSelected._id) {
      const result = await this.addTeamMember({
        teamId: this.team._id,
        memberId: this.userSelected._id
      });
      if (result) {
        this.showNotification = true;
      }
    }
  }

  public closeDialog() {
    this.showNotification = false;
  }

  @teams.Action
  addTeamMember!: (addTeamMember: AddTeamMemberType) => boolean;
}
