




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Team } from '../../types/team.type';
import UpdateTeam from './update-team.vue';

@Component({
  name: 'UpdateTeamFullName',
  components: { UpdateTeam }
})
export default class UpdateTeamFullName extends Vue {
  @Prop() team!: Team;

  @Prop({ default: false }) showNotification!: boolean;
}
