import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import { CreateTeam } from '../types/create-team.type';
import { Team } from '../types/team.type';
import { User } from '../types/user.type';
import { AddTeamMember } from '../types/add-team-member.type';
import { UpdateTeam } from '../types/update-team.type';
import { AddTeamManager } from '../types/add-manager.type';
import { RemoveTeamManager } from '../types/remove-manager.type';
import { RemoveTeamMember } from '../types/remove-team-member.type';

@Module({ namespaced: true })
export default class Teams extends VuexModule {
  public newTeam: CreateTeam = {
    fullName: '',
    description: '',
    leaderId: ''
  };

  public team: Team = {
    _id: '',
    fullName: '',
    description: ''
  };

  public teams: Team[] = [];

  public teamsNotUser: User[] = [];

  @Mutation
  public setNewTeam(newTeam: CreateTeam): void {
    this.newTeam = newTeam;
  }

  @Mutation
  public clearNewTeam(): void {
    this.newTeam = {
      fullName: '',
      description: '',
      leaderId: ''
    };
  }

  @Mutation
  public setTeam(team: Team): void {
    this.team = { ...team };
  }

  @Mutation
  public setTeams(teams: Team[]): void {
    this.teams = teams;
  }

  @Mutation
  public setTeamsNotUser(users: User[]): void {
    this.teamsNotUser = users;
  }

  @Action
  public async createTeam(): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const { newTeam } = this;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Settings($createTeamInput: CreateTeamInput!) {
          createTeam(createTeamInput: $createTeamInput) {
            _id
            fullName
            description
          }
        }
      `,
      variables: { createTeamInput: newTeam },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.createTeam) {
      this.context.commit('clearNewTeam');
      this.context.dispatch('getTeamsFromRemote');
      return true;
    }
    return false;
  }

  @Action
  public async getTeamFromRemote(_id: string): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Team($_id: String!) {
          getTeam(_id: $_id) {
            _id
            fullName
            description
            leaderId
            leader {
              _id
              firstName
              lastName
            }
            membersId
            members {
              _id
              firstName
              lastName
            }
            managersId
            managers {
              _id
              firstName
              lastName
            }
          }
        }
      `,
      variables: { _id },
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getTeam) {
      this.context.commit('setTeam', result.data.getTeam);
      return true;
    }
    return false;
  }

  @Action
  public async updateTeam(team: UpdateTeam): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Settings($updateTeamInput: UpdateTeamInput!) {
          updateTeam(updateTeamInput: $updateTeamInput)
        }
      `,
      variables: { updateTeamInput: team },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.updateTeam) {
      this.context.dispatch('getTeamFromRemote', team._id);
      this.context.dispatch('getTeamsFromRemote');
      return true;
    }
    return false;
  }

  @Action
  public async addTeamMember(addTeamMember: AddTeamMember): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Settings($addTeamMemberInput: AddTeamMemberInput!) {
          addTeamMember(addTeamMemberInput: $addTeamMemberInput)
        }
      `,
      variables: { addTeamMemberInput: addTeamMember },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.addTeamMember) {
      this.context.dispatch('getTeamsFromRemote');
      this.context.dispatch('users/getUsersFromRemote', '', { root: true });
      this.context.dispatch('getTeamFromRemote', addTeamMember.teamId);
      return true;
    }
    return false;
  }

  @Action
  public async removeTeamMember(
    removeTeamMember: RemoveTeamMember
  ): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Team($removeTeamMemberInput: RemoveTeamMemberInput!) {
          removeTeamMember(removeTeamMemberInput: $removeTeamMemberInput)
        }
      `,
      variables: { removeTeamMemberInput: removeTeamMember },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.removeTeamMember) {
      this.context.dispatch('getTeamsFromRemote');
      this.context.dispatch('users/getUsersFromRemote', '', { root: true });
      this.context.dispatch('getTeamFromRemote', removeTeamMember.teamId);
      return true;
    }
    return false;
  }

  @Action
  public async addTeamManager(
    addTeamManager: AddTeamManager
  ): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Settings($addTeamManagerInput: AddTeamManagerInput!) {
          addTeamManager(addTeamManagerInput: $addTeamManagerInput)
        }
      `,
      variables: { addTeamManagerInput: addTeamManager },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.addTeamManager) {
      this.context.dispatch('getTeamsFromRemote');
      this.context.dispatch('getTeamFromRemote', addTeamManager.teamId);
      this.context.dispatch('users/getUsersFromRemote', '', { root: true });
      return true;
    }
    return false;
  }

  @Action
  public async removeManager(
    removeTeamManager: RemoveTeamManager
  ): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Settings($removeTeamManagerInput: RemoveTeamManagerInput!) {
          removeTeamManager(removeTeamManagerInput: $removeTeamManagerInput)
        }
      `,
      variables: { removeTeamManagerInput: removeTeamManager },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.removeTeamManager) {
      this.context.dispatch('getTeamsFromRemote');
      this.context.dispatch('getTeamFromRemote', removeTeamManager.teamId);
      this.context.dispatch('users/getUsersFromRemote', '', { root: true });
      return true;
    }
    return false;
  }
  @Action
  public async deleteTeamFromRemote(_id: string): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Settings($_id: String!) {
          deleteTeam(_id: $_id)
        }
      `,
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.deleteTeam) {
      this.context.dispatch('getTeamsFromRemote');
      return true;
    }
    return false;
  }

  public async removeTeamFromRemote(_id: string): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Settings($_id: String!) {
          removeTeam(_id: $_id)
        }
      `,
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.removeTeam) {
      this.context.dispatch('getTeamsFromRemote');
    }
  }

  @Action({ rawError: true })
  public async getTeamsFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Settings {
          findAllTeams {
            _id
            fullName
            description
            leaderId
            leader {
              _id
              firstName
              lastName
            }
            membersId
            members {
              _id
              firstName
              lastName
            }
            managersId
            managers {
              _id
              firstName
              lastName
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.findAllTeams) {
      this.context.commit('setTeams', result.data.findAllTeams);
    }
  }

  @Action({ rawError: true })
  public async getMyTeamsFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Settings {
          findMyTeams {
            _id
            fullName
            description
            leaderId
            leader {
              _id
              firstName
              lastName
            }
            membersId
            members {
              _id
              firstName
              lastName
            }
            managersId
            managers {
              _id
              firstName
              lastName
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.findMyTeams) {
      this.context.commit('setTeams', result.data.findMyTeams);
    }
  }

  @Action
  public async getTeamsNotUserFromRemote(userId: string) {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Settings($_id: String!) {
          findAllTeamsNotUser(_id: $_id) {
            _id
            fullName
            description
            leader {
              _id
              firstName
              lastName
            }
          }
        }
      `,
      variables: { _id: userId },
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.findAllTeamsNotUser)
      this.context.commit('setTeamsNotUser', result.data.findAllTeamsNotUser);
  }

  @Action({ rawError: true })
  public async removeRoleRemote(_id: string): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Settings($_id: String!) {
          removeRole(_id: $_id)
        }
      `,
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.removeRole) {
      this.context.dispatch('teams/getRolesFromRemote', '', { root: true });
    }
  }
}
