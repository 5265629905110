






















import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
import { Employee } from '../types/employee.type';

@Component({
  name: 'UpdateEmployeeNationalId',
  components: { UpdateEmployee }
})
export default class UpdateEmployeeNationalId extends Vue {
  @Prop() employee!: Employee;

  @Prop({ default: false }) showNotification!: boolean;
}
