


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectUser from './select-user.vue';
import { User } from '../../types/user.type';
import { Team } from '../../types/team.type';

const teams = namespace('teams');

@Component({
  name: 'ChooseLeader',
  components: { Modal, SelectUser }
})
export default class ChooseLeader extends Vue {
  @Prop()
  public team!: Team;

  public showNotification = false;

  public onSelectUser(user: User): void {
    this.setTeam({ ...this.team, leaderId: user._id });
  }

  public async save(): Promise<void> {
    if (this.team._id && this.team.leaderId) {
      const result = await this.updateTeam(this.team);
      if (result) {
        this.showNotification = true;
      }
    }
  }

  public closeDialog(): void {
    this.showNotification = false;
  }

  @teams.Mutation
  setTeam!: (team: Team) => void;

  @teams.Action
  updateTeam!: (team: Team) => Promise<boolean>;
}
