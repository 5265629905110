























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Header from '@/components/header.vue';
import TimelineItem from './../components/TimelineItem.vue';
import EmployeesOnHoliday from './../components/employees-on-holiday.vue';
import ToDoList from './../components/to-do-list.vue';
import { namespace } from 'vuex-class';
import Celebrations from '../components/celebrations.vue';
import Upcoming from '../components/Upcoming.vue';
import ToDoItem from '../types/ToDoItem.type';
import Profile from '@/components/profile.vue';
import { User } from '@/modules/settings/types/user.type';

const overview = namespace('overview');
const timeOff = namespace('timeOff');
const requests = namespace('requests');
const profile = namespace('profile');
const application = namespace('application');

@Component({
  components: {
    Header,
    TimelineItem,
    ToDoList,
    EmployeesOnHoliday,
    Celebrations,
    Upcoming,
    Profile
  }
})
export default class OverviewManager extends Vue {
  @overview.State
  public toDo!: ToDoItem[];

  @overview.State
  public timeline!: TimelineItem[];

  @timeOff.State
  public timeOffCount!: number;

  @requests.State
  public requestsCount!: number;

  @profile.State
  public profile!: User;

  private async created() {
    this.setIsNavigation(true);
    this.setTitle(`Good afternoon, ${this.profile.firstName}`);
    this.getTimeLineFromRemote();
    await this.getTimeOffForMeCount();
    await this.getRequestsForMeCount();
  }

  @overview.Action
  private getTimeLineFromRemote!: () => void;

  @timeOff.Action
  getTimeOffForMeCount!: () => Promise<void>;

  @requests.Action
  getRequestsForMeCount!: () => Promise<void>;

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;
}
