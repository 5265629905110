import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import router from '@/router';
import CreateTimeOff from '../types/create-time-off.type';
import TimeOffType from '../types/time-off.type';
import { ApolloQueryResult } from 'apollo-client';
import PLRSummary from '../types/paid-leave-rights.type';

@Module({ namespaced: true })
export default class TimeOff extends VuexModule {
  public newTimeOff: CreateTimeOff = {
    type: '',
    startDate: undefined,
    endDate: undefined,
    creationDate: new Date(),
    value: 0,
    status: '',
    note: ''
  };
  public timeOffBalance = 0;
  public timeOff: TimeOffType[] = [];
  public timeOffCount = 0;
  public startDateDisplay: Date | string = '';
  public endDateDisplay: Date | string = '';
  public plrSummaries: PLRSummary[] = [];

  @Mutation
  public clearNewTimeOff(): void {
    this.newTimeOff = {
      type: '',
      startDate: undefined,
      endDate: undefined,
      creationDate: new Date(),
      value: 0,
      status: '',
      note: ''
    };
  }

  @Mutation
  public setPLRSummaries(plrSummaries: PLRSummary[]): void {
    this.plrSummaries = plrSummaries;
  }

  @Mutation
  public setNewTimeOff(newTimeOff: CreateTimeOff): void {
    this.newTimeOff = newTimeOff;
  }

  @Mutation
  public setTimeOffBalance(timeOffBalance: number): void {
    this.timeOffBalance = timeOffBalance;
  }

  @Mutation
  public setTimeOff(timeOff: TimeOffType[]): void {
    this.timeOff = timeOff;
  }

  @Mutation
  public setTimeOffForMeCount(count: number): void {
    this.timeOffCount = count;
  }

  @Action
  public async getTimeOffFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result: any = await apolloClient.query({
      query: gql`
        query TimeOff {
          findAll {
            _id
            employeeId
            fullName
            startDate
            endDate
            type
            note
            status
            value
            processed
            creationDate
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.findAll)
      this.context.commit('setTimeOff', result.data.findAll);
  }

  @Action
  public async getMyTimeOffFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result: any = await apolloClient.query({
      query: gql`
        query TimeOff {
          myTimeOff {
            _id
            employeeId
            fullName
            startDate
            endDate
            type
            note
            status
            value
            processed
            creationDate
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.myTimeOff)
      this.context.commit('setTimeOff', result.data.myTimeOff);
  }

  @Action
  public async getTimeOffForMeFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result: any = await apolloClient.query({
      query: gql`
        query TimeOff {
          getTimeOffForMe {
            _id
            employeeId
            employee {
              firstName
              lastName
            }
            fullName
            startDate
            endDate
            type
            note
            status
            value
            processed
            creationDate
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getTimeOffForMe)
      this.context.commit('setTimeOff', result.data.getTimeOffForMe);
  }

  @Action
  public async getTimeOffForMeCount() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query TimeOff {
          getTimeOffForMeCount
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getTimeOffForMeCount)
      this.context.commit(
        'setTimeOffForMeCount',
        result.data.getTimeOffForMeCount
      );
  }

  @Action
  public async createTimeOff(): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const { newTimeOff } = this;
    const result: any = await apolloClient.mutate({
      mutation: gql`
        mutation TimeOff($createTimeOffInput: CreateTimeOffInput!) {
          createTimeOff(createTimeOffInput: $createTimeOffInput) {
            processed
            value
            startDate
            endDate
          }
        }
      `,
      variables: { createTimeOffInput: newTimeOff },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.createTimeOff) {
      this.context.commit('clearNewTimeOff');
      this.context.dispatch('getMyTimeOffFromRemote');
      return true;
    }
    return false;
  }

  @Action
  public async approveTimeOff(approveTimeOffInput: {
    _id: string;
    review: string;
  }): Promise<TimeOff | null> {
    const { token, host } = this.context?.rootState?.auth;
    const result: any = await apolloClient.mutate({
      mutation: gql`
        mutation TimeOff($approveTimeOffInput: ApproveTimeOffInput!) {
          approveTimeOff(approveTimeOffInput: $approveTimeOffInput)
        }
      `,
      variables: { approveTimeOffInput },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });

    if (result && result.data && result.data.approveTimeOff) {
      await this.context.dispatch('getTimeOffForMeFromRemote');
      return result.data.approveTimeOff;
    }
    return null;
  }

  @Action
  public async rejectTimeOff(rejectTimeOffInput: {
    _id: string;
    review: string;
  }): Promise<TimeOff | null> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation TimeOff($rejectTimeOffInput: RejectTimeOffInput!) {
          rejectTimeOff(rejectTimeOffInput: $rejectTimeOffInput)
        }
      `,
      variables: { rejectTimeOffInput },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });

    if (result && result.data && result.data.rejectTimeOff) {
      await this.context.dispatch('getTimeOffForMeFromRemote');
      await this.context.dispatch('getTimeOffForMeFromRemote');
      return result.data.rejectTimeOff;
    }
    return null;
  }

  @Action({ rawError: true })
  public async getTimeOffBalanceFromRemote(employeeId: string): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Employee($_id: String!) {
          getTimeOffBalance(_id: $_id)
          Number
        }
      `,
      variables: { _id: employeeId },
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getTimeOffBalance) {
      this.context.commit('setTimeOffBalance', result.data.getTimeOffBalance);
    }
  }

  @Action({ rawError: true })
  public async myTimeOffBalanceFromRemote(): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Employee {
          myTimeOffBalance
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.myTimeOffBalance) {
      this.context.commit('setTimeOffBalance', result.data.myTimeOffBalance);
    }
  }

  @Action({ rawError: true })
  public async removeTimeOffFromRemote(_id: string): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation TimeOff($_id: String!) {
          removeTimeOff(_id: $_id)
        }
      `,
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.removeTimeOff) {
      this.context.dispatch('getMyTimeOffFromRemote');
    }
  }

  @Action({ rawError: true })
  public async getPLRSummariesFromRemote(): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query PL {
          getSummaries {
            _id
            startDate
            endDate
            creationDate
            status
            timeOffsIds
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getSummaries) {
      this.context.commit('setPLRSummaries', result.data.getSummaries);
    }
  }
}
