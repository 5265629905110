


























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectManagerNotTeam from './select-manager-not-team.vue';
import { Team } from '../../types/team.type';
import { User } from '../../types/user.type';
import { AddTeamManager as AddTeamManagerType } from '../../types/add-manager.type';

const teams = namespace('teams');

@Component({
  name: 'AddTeamManager',
  components: { Modal, SelectManagerNotTeam }
})
export default class AddTeamManager extends Vue {
  @teams.State
  public team!: Team;

  public showNotification = false;

  public userSelected: User = {
    _id: '',
    firstName: '',
    lastName: ''
  };

  public setUserSelected(userSelected: User) {
    this.userSelected = {
      _id: userSelected._id,
      firstName: userSelected.firstName,
      lastName: userSelected.lastName
    };
  }

  public async save() {
    if (this.userSelected && this.userSelected._id) {
      const result = await this.addTeamManager({
        teamId: this.team._id,
        managerId: this.userSelected._id
      });
      if (result) {
        this.showNotification = true;
      }
    }
  }

  public closeDialog() {
    this.showNotification = false;
  }

  @teams.Action
  addTeamManager!: (addTeamManager: AddTeamManagerType) => boolean;
}
