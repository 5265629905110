



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Team } from '../../types/team.type';

const teams = namespace('teams');

@Component({
  name: 'SelectTeam'
})
export default class SelectTeam extends Vue {
  @teams.State
  public teams!: Team[];

  @Prop() team!: Team;

  @Prop({ default: false }) all!: boolean;

  @Prop({ default: 'Team' }) label!: string;

  get items() {
    return this.all ? [{ fullName: 'All' }, ...this.teams] : this.teams;
  }

  private async created() {
    await this.getMyTeamsFromRemote();
  }

  @teams.Action
  public getMyTeamsFromRemote!: () => Promise<void>;

  @teams.Action
  public getTeamsFromRemote!: () => Promise<void>;
}
