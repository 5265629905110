



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectEmployee from './select-employee.vue';
import { User } from '../../types/user.type';
import { Employee } from '../../types/employee.type';
import { LinkAccountInput } from '../../types/link-account.type';

const users = namespace('users');

@Component({
  name: 'ChooseEmployee',
  components: { Modal, SelectEmployee }
})
export default class ChooseEmployee extends Vue {
  @Prop()
  public user!: User;

  public showNotification = false;

  public async selectEmployee(employee: Employee): Promise<void> {
    await this.setUser({ ...this.user, employeeId: employee._id });
  }

  public async save(): Promise<void> {
    if (this.user._id && this.user.employeeId) {
      const result = await this.linkAccount({
        userId: this.user._id,
        employeeId: this.user.employeeId
      });
      if (result) {
        this.showNotification = true;
      }
    }
  }

  public closeDialog(): void {
    this.showNotification = false;
  }

  @users.Mutation
  setUser!: (user: User) => boolean;

  @users.Action
  linkAccount!: (linkAccount: LinkAccountInput) => Promise<boolean>;
}
