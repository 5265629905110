




















import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '../../types/user.type';
import UpdateUser from './update-user.vue';

@Component({
  name: 'UpdateUserMail',
  components: { UpdateUser }
})
export default class UpdateUserMail extends Vue {
  @Prop() user!: User;

  @Prop({ default: false }) showNotification!: boolean;
}
