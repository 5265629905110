









































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import { CreateTeam } from '../../types/create-team.type';
import SelectUser from '../user/select-user.vue';
import SelectRole from '../role/select-role.vue';
import { User } from '../../types/user.type';

const teams = namespace('teams');

@Component({
  name: 'NewTeam',
  components: { Modal, SelectUser, SelectRole }
})
export default class NewTeam extends Vue {
  @teams.State
  newTeam!: CreateTeam;

  public showNotification = false;

  public selectUser(leader: User): void {
    if (leader && leader._id)
      this.setNewTeam({ ...this.newTeam, leaderId: leader._id });
  }

  public async save(): Promise<void> {
    const result = await this.createTeam();
    if (result) {
      this.showNotification = true;
    }
  }

  public closeDialog(): void {
    this.showNotification = false;
  }

  @teams.Mutation
  setNewTeam!: (newTeam: CreateTeam) => boolean;

  @teams.Action
  createTeam!: () => boolean;
}
