
















import { Component, Vue, Prop } from 'vue-property-decorator';

interface Item {
  title: string;
  subtitle: string;
}

@Component({})
export default class ToDoListItem extends Vue {
  @Prop() private item!: Item;
}
