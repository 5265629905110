







































import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
import { Employee } from '../types/employee.type';
import { namespace } from 'vuex-class';

const employees = namespace('employees');

@Component({
  name: 'UpdateEmployeeHiredate',
  components: { UpdateEmployee }
})
export default class UpdateEmployeeHiredate extends Vue {
  @Prop() employee!: Employee;

  @Prop({ default: false }) showNotification!: boolean;

  public hiredateMenu = false;

  public updateDate(hiredate: Date) {
    this.$emit('hiredate', hiredate);
    this.hiredateMenu = false;
  }
}
