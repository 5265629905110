








































































































































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Employee as EmployeeType } from './../types/employee.type';
import Employee from './../components/employee.vue';
import NewEmployee from './../components/new-employee.vue';
import EmployeeList from './../components/employee-list.vue';

const employees = namespace('employees');
const application = namespace('application');

@Component({
  name: 'Employees',
  components: { Header, NewEmployee, Employee, EmployeeList }
})
export default class Employees extends Vue {
  public search = '';

  public searchFilter = '*';

  public isTerminated = 0;

  public drawer = false;

  public isList = 0; // 0 mean isList | 1 mean isCard

  public headers = [
    {
      text: '',
      value: 'avatar'
    },

    {
      text: 'Employee',
      value: 'firstName'
    },

    { text: 'Team', value: 'team.fullName' },
    { text: 'Job title', value: 'jobTitle' },
    { text: 'Mail', value: 'mail' },
    /* { text: 'Next Pay Day', value: 'nextPayDay' }, */
    { text: '', value: 'actions' },
    {
      text: '',
      value: 'lastName'
    }
  ];

  @employees.State
  public employees!: EmployeeType[];

  @employees.State
  public employee!: EmployeeType;

  get displayEmployees() {
    switch (this.isTerminated) {
      case 0:
        return this.employees.filter(employee => {
          return (
            employee != null &&
            ((employee.firstName &&
              this.search != null &&
              typeof this.search === 'string' &&
              employee.firstName
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
              (employee.lastName &&
                this.search != null &&
                typeof this.search === 'string' &&
                employee.lastName
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
              (employee.jobTitle &&
                this.search != null &&
                typeof this.search === 'string' &&
                employee.jobTitle
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
              (employee.mail &&
                this.search != null &&
                typeof this.search === 'string' &&
                employee.mail
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
              (employee.phone &&
                this.search != null &&
                typeof this.search === 'string' &&
                employee.phone
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
            employee.terminated === false
          );
        });
      case 1:
        return this.employees.filter(employee => {
          return (
            employee != null &&
            ((employee.firstName &&
              this.search != null &&
              typeof this.search === 'string' &&
              employee.firstName
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
              (employee.lastName &&
                this.search != null &&
                typeof this.search === 'string' &&
                employee.lastName
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
              (employee.jobTitle &&
                this.search != null &&
                typeof this.search === 'string' &&
                employee.jobTitle
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
              (employee.mail &&
                this.search != null &&
                typeof this.search === 'string' &&
                employee.mail
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
              (employee.phone &&
                this.search != null &&
                typeof this.search === 'string' &&
                employee.phone
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
            employee.terminated === true
          );
        });
      default:
        return [];
    }
  }

  private async created(): Promise<void> {
    this.setIsNavigation(true);
    this.setTitle('People');
    await this.getMyEmployeesFromRemote();
  }

  public onTerminated(): void {
    this.isTerminated = 1;
  }

  public onPayroll(): void {
    this.isTerminated = 0;
  }

  /*   public searchByName(args: string) {
    this.search = args;
    this.searchFilter = '*';
  } */

  public showItem(employee: EmployeeType) {
    this.drawer = true;
    this.setEmployee(employee);
  }

  public onList() {
    this.isList = 0;
  }

  public onCard() {
    this.isList = 1;
  }

  public searchByName(args: string) {
    this.search = args;
    /*  this.displayEmployees = this.employees.filter(employee => {
      if (this.isTerminated) {
        return (
          employee != null &&
          ((employee.firstName &&
            this.search != null &&
            typeof this.search === 'string' &&
            employee.firstName
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
            (employee.lastName &&
              this.search != null &&
              typeof this.search === 'string' &&
              employee.lastName
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
            (employee.jobTitle &&
              this.search != null &&
              typeof this.search === 'string' &&
              employee.jobTitle
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
            (employee.mail &&
              this.search != null &&
              typeof this.search === 'string' &&
              employee.mail
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
            (employee.phone &&
              this.search != null &&
              typeof this.search === 'string' &&
              employee.phone
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
          employee.terminated === true
        );
      } else {
        return (
          employee != null &&
          ((employee.firstName &&
            this.search != null &&
            typeof this.search === 'string' &&
            employee.firstName
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
            (employee.lastName &&
              this.search != null &&
              typeof this.search === 'string' &&
              employee.lastName
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
            (employee.jobTitle &&
              this.search != null &&
              typeof this.search === 'string' &&
              employee.jobTitle
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
            (employee.mail &&
              this.search != null &&
              typeof this.search === 'string' &&
              employee.mail
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1) ||
            (employee.phone &&
              this.search != null &&
              typeof this.search === 'string' &&
              employee.phone
                .toLocaleUpperCase()
                .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
          employee.terminated === false
        );
      }
    }); */
  }

  @employees.Mutation
  setEmployee!: (employee: EmployeeType) => void;

  @employees.Action
  getMyEmployeesFromRemote!: () => void;

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;
}
