







































































































































































































































































































import Header from '@/components/header.vue';
import { Employee } from '@/modules/settings/types/employee.type';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TimeOff from '../types/time-off.type';
import PaidLeaveRights from '../components/paid-leave-rights.vue';
import Balance from '../components/balance.vue';
import Calendar from '@/components/calendar.vue';
import dtFormat from '@/plugins/filters/dateFilter';

const timeOff = namespace('timeOff');
const application = namespace('application');
const employees = namespace('employees');

@Component({
  name: 'TimeOffManager',
  components: { Header, PaidLeaveRights, Balance, Calendar }
})
export default class TimeOffManager extends Vue {
  @employees.State
  public employees!: Employee[];

  @timeOff.State
  private timeOff!: TimeOff[];

  public newTimeOff: TimeOff = {
    _id: '',
    employeeId: '',
    fullName: '',
    type: '',
    creationDate: new Date(),
    value: 0,
    status: '',
    note: '',
    startDate: new Date(),
    endDate: new Date(),
    processed: false
  };
  public drawer = false;
  public review = '';
  public headers = [
    {
      text: 'Fullname',
      align: 'start',
      value: 'employee'
    },
    {
      text: 'Effective date',
      align: 'start',
      value: 'startDate'
    },
    {
      text: 'Creation date',
      align: 'start',
      value: 'creationDate'
    },
    { text: 'Day(s)', value: 'value' },
    { text: 'Type', value: 'type' },
    { text: 'Status', value: 'status' },
    { text: '', value: 'actions', sortable: false }
  ];
  public search = '';

  public searchFilter = '*';

  public activeTab = 0;

  private async created(): Promise<void> {
    this.setIsNavigation(true);
    this.setTitle('Time off');
    await this.getTimeOffForMeFromRemote();
    await this.getMyEmployeesFromRemote();
  }

  public events: Event[] = [];

  get getEvents() {
    return this.timeOff
      .filter(item => item.type !== 'Paid Leave Rights')
      .map(item => {
        return {
          name:
            item.type +
            ' ' +
            item.employee?.firstName +
            ' ' +
            item.employee?.lastName,
          start: dtFormat(item.startDate?.toString() || '', 'YYYY-MM-DD'),
          end: dtFormat(item.endDate?.toString() || '', 'YYYY-MM-DD'),
          timed: false,
          color: item.status === 'approved' ? 'success' : 'primary'
        };
      });
  }

  public searchByName(args: string) {
    this.search = args;
    this.searchFilter = '*';
  }

  public onAll(): void {
    this.activeTab = 0;
  }

  public onCurrent(): void {
    this.activeTab = 1;
  }

  public onApproved(): void {
    this.activeTab = 2;
  }

  public onRejected(): void {
    this.activeTab = 3;
  }

  public onRequests(): void {
    /* this.search = 'waiting'; */
    this.searchFilter = '*';
    this.activeTab = 0;
  }

  public onHistory(): void {
    /* this.search = 'processed'; */
    this.searchFilter = '*';
  }

  public filterRequests(value: unknown, search: string, item: TimeOff) {
    switch (this.activeTab) {
      case 0:
        if (this.search.length) {
          return (
            value != null &&
            this.search != null &&
            typeof value === 'string' &&
            (value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
              (item.employee &&
                (item.employee.firstName
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  item.employee.lastName
                    .toString()
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
              (item.note &&
                item.note
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1))
          );
        }
        return true;
      case 1:
        if (this.search.length) {
          return (
            value != null &&
            this.search != null &&
            typeof value === 'string' &&
            (value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
              (item.employee &&
                (item.employee.firstName
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  item.employee.lastName
                    .toString()
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
              (item.note &&
                item.note
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
            item.processed === false
          );
        }
        return item.processed === false;
      case 2:
        if (this.search.length) {
          return (
            value != null &&
            this.search != null &&
            typeof value === 'string' &&
            (value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
              (item.employee &&
                (item.employee.firstName
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  item.employee.lastName
                    .toString()
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
              (item.note &&
                item.note
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
            item.processed === true &&
            item.status === 'approved'
          );
        }
        return item.processed === true && item.status === 'approved';
      case 3:
        if (this.search.length) {
          return (
            value != null &&
            this.search != null &&
            typeof value === 'string' &&
            (value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
              (item.employee &&
                (item.employee.firstName
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  item.employee.lastName
                    .toString()
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
              (item.note &&
                item.note
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1)) &&
            item.processed === true &&
            item.status === 'rejected'
          );
        }
        return item.processed === true && item.status === 'rejected';
      default:
        if (this.search.length) {
          return (
            value != null &&
            this.search != null &&
            typeof value === 'string' &&
            (value
              .toString()
              .toLocaleUpperCase()
              .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
              (item.employee &&
                (item.employee.firstName
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1 ||
                  item.employee.lastName
                    .toString()
                    .toLocaleUpperCase()
                    .indexOf(this.search.toLocaleUpperCase()) !== -1)) ||
              (item.note &&
                item.note
                  .toString()
                  .toLocaleUpperCase()
                  .indexOf(this.search.toLocaleUpperCase()) !== -1))
          );
        }
        return true;
    }
  }

  public showItem(newTimeOff: TimeOff) {
    this.newTimeOff = newTimeOff;
    this.drawer = true;
  }

  public async accept(_id: string) {
    const { review } = this;
    const result = await this.approveTimeOff({ _id, review });
    if (result) {
      this.drawer = false;
    }
  }

  public async decline(_id: string) {
    const { review } = this;
    const result = await this.rejectTimeOff({ _id, review });
    if (result) {
      this.drawer = false;
    }
  }

  @timeOff.Action
  private getTimeOffForMeFromRemote!: () => Promise<void>;

  @timeOff.Action
  approveTimeOff!: (approveTimeOffInput: {
    _id: string;
    review: string;
  }) => Promise<Request | null>;

  @timeOff.Action
  rejectTimeOff!: (rejectTimeOffInput: {
    _id: string;
    review: string;
  }) => Promise<Request | null>;

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;

  @employees.Action
  getMyEmployeesFromRemote!: () => void;
}
