import auth from '@/guards/auth.guard';
import { RouteConfig } from 'vue-router';
import Employees from './../views/employees.vue';

const employeesRoutes: Array<RouteConfig> = [
  {
    path: '/employees',
    name: 'Employees',
    component: Employees,
    meta: {
      middleware: [auth]
    }
  }
];

export default employeesRoutes;
