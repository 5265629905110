























import { Employee } from '@/modules/settings/types/employee.type';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'EmployeeListItem'
})
export default class EmployeeListItem extends Vue {
  @Prop()
  public employee!: Employee;

  @Prop({ default: 0 })
  public index!: number;

  @Prop({ default: false })
  public select!: boolean;

  @Prop({ default: false })
  public active!: boolean;

  public onCard() {
    this.$emit('employee', {
      ...this.employee,
      index: this.index,
      active: !this.active
    });
  }
}
