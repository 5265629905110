




import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue';

@Component({
  name: 'Payslip',
  components: { Header }
})
export default class Payslip extends Vue {}
