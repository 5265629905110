










































































































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User as UserType } from '../../types/user.type';
import Label from '@/components/label.vue';
import Value from '@/components/value.vue';
import LabelAndValue from '@/components/label-and-value.vue';
import UserTeams from './user-teams.vue';
import AddTeam from '../team/add-team.vue';
import ChooseEmployee from '../employee/choose-employee.vue';
import UpdateUserRole from './update-user-role.vue';
import { UpdateUser } from '../../types/update-user.type';
import UpdateUserMail from './update-user-mail.vue';
import UpdateUserFirstNameAndLastName from './update-user-firstname-and-lastname.vue';
import DeleteUser from './delete-user.vue';

const users = namespace('users');

@Component({
  name: 'User',
  components: {
    AddTeam,
    ChooseEmployee,
    DeleteUser,
    Header,
    LabelAndValue,
    UserTeams,
    Label,
    Value,
    UpdateUserRole,
    UpdateUserMail,
    UpdateUserFirstNameAndLastName
  }
})
export default class User extends Vue {
  @users.State
  public user!: UserType;

  public isActivate = false;

  public showNotification = false;

  public onActivate(activate: boolean) {
    if (this.user && this.user._id)
      activate
        ? this.activeAccount(this.user._id)
        : this.disableAccount(this.user._id);
  }

  public async cancelUpdate(): Promise<void> {
    if (this.user && this.user._id) await this.getUserFromRemote(this.user._id);
  }

  public close(): void {
    this.showNotification = false;
  }

  public async updateUserFirstNameAndLastName() {
    if (
      this.user &&
      this.user._id &&
      this.user.firstName &&
      this.user.lastName
    ) {
      const isUpdated = await this.updateUser({
        _id: this.user._id,
        firstName: this.user.firstName,
        lastName: this.user.lastName
      });

      if (isUpdated) {
        this.showNotification = true;
      }
    }
  }

  public async updateUserRole() {
    if (this.user && this.user._id && this.user.roleId) {
      const isUpdated = await this.updateUser({
        _id: this.user._id,
        roleId: this.user.roleId
      });

      if (isUpdated) {
        this.showNotification = true;
      }
    }
  }

  public async updateUserMail() {
    if (this.user && this.user._id && this.user.mail) {
      const isUpdated = await this.updateUser({
        _id: this.user._id,
        mail: this.user.mail
      });

      if (isUpdated) {
        this.showNotification = true;
      }
    }
  }

  @users.Action
  public activeAccount!: (_id: string) => Promise<boolean>;

  @users.Action
  public disableAccount!: (_id: string) => Promise<boolean>;

  @users.Action
  getUserFromRemote!: (_id: string) => Promise<void>;

  @users.Action
  updateUser!: (team: UpdateUser) => Promise<boolean>;
}
