























































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
import Label from '@/components/label.vue';
import Value from '@/components/value.vue';
import LabelAndValue from '@/components/label-and-value.vue';
import { Team } from '../../types/team.type';
import { Employee } from '../../types/employee.type';
import { User } from '../../types/user.type';

@Component({
  name: 'TeamListItem',
  components: { Modal, Label, LabelAndValue, Value }
})
export default class TeamListItem extends Vue {
  @Prop() team!: Team;

  public stackedMenu = false;

  public stackedManagerMenu = false;

  public menuMaxHeight = '200px';

  public avatarsStackedLimited: Employee[] = [];

  public managerAvatarsStackedLimited: User[] = [];

  private created(): void {
    if (this.team && this.team.members) {
      this.avatarsStackedLimited = this.team.members.slice(0, 3);
    }

    if (this.team && this.team.managers) {
      this.managerAvatarsStackedLimited = this.team.managers.slice(0, 3);
    }
  }
}
