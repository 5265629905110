





















import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';

@Component({
  name: 'UpdateRole',
  components: { Modal }
})
export default class UpdateRole extends Vue {
  @Prop({ default: false })
  public showNotification!: boolean;

  @Prop({ default: true })
  public cardText!: boolean;

  @Prop({ default: '' })
  public maxWidth!: string;

  @Prop({ default: false })
  public fullscreen!: boolean;
}
