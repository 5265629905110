








import { Component, Prop, Vue } from 'vue-property-decorator';
import { Team } from '../../types/team.type';
import TeamListItem from './team-list-item.vue';

@Component({
  name: 'TeamList',
  components: { TeamListItem }
})
export default class TeamList extends Vue {
  @Prop() teams!: Team[];
}
