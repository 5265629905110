










import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PayrollSummariesReceivers from './application/payroll-summaries-receivers.vue';

const application = namespace('application');

@Component({
  name: 'Application',
  components: { Header, PayrollSummariesReceivers }
})
export default class Application extends Vue {
  private created(): void {
    this.setTitle('Application Settings');
  }

  @application.Mutation
  setTitle!: (title: string) => void;
}
