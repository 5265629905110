




















import { Employee } from '../../types/employee.type';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const members = namespace('members');

@Component({
  name: 'SelectEmployeeNotUser'
})
export default class SelectEmployeeNotUser extends Vue {
  @members.State
  public employees!: Employee[];

  @Prop({ default: 'Employee' }) label!: string;

  private created() {
    this.getEmployeesNotUserFromRemote();
  }

  private updated() {
    this.getEmployeesNotUserFromRemote();
  }

  @members.Action
  getEmployeesNotUserFromRemote!: () => void;
}
