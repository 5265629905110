


















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Pricing from './../components/pricing.vue';
import Subscription from './../components/subscription.vue';
import Products from './../components/products.vue';

const application = namespace('application');

@Component({
  name: 'Home',
  components: {
    Pricing,
    Subscription,
    Products
  }
})
export default class Home extends Vue {
  private created(): void {
    this.setIsNavigation(false);
    this.setIsMenuHome(true);
  }

  private destroyed(): void {
    this.setIsMenuHome(false);
  }

  private destroy(): void {
    this.setIsNavigation(false);
    this.setIsMenuHome(true);
  }

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;
  @application.Mutation
  setIsMenuHome!: (isNavigation: boolean) => void;
}
