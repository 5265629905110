















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const overview = namespace('overview');

@Component({
  name: 'Upcoming'
})
export default class Upcoming extends Vue {}
