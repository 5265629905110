






































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import { CreateRole } from '../../types/create-role.type';

const roles = namespace('roles');

@Component({
  name: 'NewRole',
  components: { Modal }
})
export default class NewRole extends Vue {
  @roles.State
  newRole!: CreateRole;

  public showNotification = false;

  public async save() {
    const result = await this.createRole();
    if (result) {
      this.showNotification = true;
    }
  }

  public closeDialog() {
    this.showNotification = false;
  }

  @roles.Action
  createRole!: () => boolean;
}
