import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import router from '@/router';
import { Payslip } from '../types/payslip.type';
import { Employee } from '../types/employee.type';
import { RequestPaydoc } from '../types/request-paydoc.type';

@Module({ namespaced: true })
export default class Paydocs extends VuexModule {
  public payslips: Payslip[] = [];
  public paydocsRequests: Payslip[] = [];
  public employees: Employee[] = [];
  public myLastPayslip: Payslip = {};
  public paydocs = [];

  public employeeId = '';

  @Mutation
  public setPayslips(payslips: Payslip[]): void {
    this.payslips = payslips;
  }

  @Mutation
  public setMyLastPayslip(myLastPayslip: Payslip): void {
    this.myLastPayslip = myLastPayslip;
  }

  @Mutation
  public setPaydocsRequests(paydocsRequests: Payslip[]): void {
    this.paydocsRequests = paydocsRequests;
  }

  @Mutation
  setEmployees(employees: Employee[]) {
    this.employees = employees;
  }

  @Mutation
  setPaydocs(paydocs: []) {
    this.paydocs = paydocs;
  }

  @Action
  public async getPayslipsFromRemote(_id: string): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Paydoc($_id: String!) {
          payslips(_id: $_id) {
            fullName
            category
            period
            creationDate
            fileId
            delivred
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.payslips) {
      this.context.commit('setPayslips', result.data.payslips);
    }
  }

  @Action
  public async getMyPayslipsFromRemote(): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Paydoc {
          getMyPayslips {
            fullName
            category
            period
            creationDate
            fileId
            delivred
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getMyPayslips) {
      this.context.commit('setPayslips', result.data.getMyPayslips);
    }
  }

  @Action
  public async getMyLastPayslip(): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Paydoc {
          getMyLastPayslip {
            fullName
            category
            period
            creationDate
            fileId
            delivred
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getMyLastPayslip) {
      this.context.commit('setMyLastPayslip', result.data.getMyLastPayslip);
    }
  }

  @Action
  public async getPaydocsRequestsForMeFromRemote(): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Paydoc {
          getPaydocsRequestsForMe {
            _id
            fullName
            category
            period
            creationDate
            delivred
            employee {
              _id
              firstName
              lastName
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getPaydocsRequestsForMe) {
      this.context.commit(
        'setPaydocsRequests',
        result.data.getPaydocsRequestsForMe
      );
    }
  }

  @Action
  public async getEmployeesFromRemote(): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Employee {
          employees {
            _id
            nId
            ssId
            cId
            firstName
            lastName
            fullName
            terminated
            team {
              _id
              fullName
            }
            teamId
          }
        }
      `,
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.employees) {
      this.context.commit('setEmployees', result.data.employees);
    }
  }

  @Action({ rawError: true })
  public async createRequestPaydoc(
    requestPaydocInput: RequestPaydoc
  ): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Paydoc($requestPaydocInput: RequestPaydocInput!) {
          createRequestPaydoc(requestPaydocInput: $requestPaydocInput)
        }
      `,
      variables: {
        requestPaydocInput
      },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.createRequestPaydoc) {
      return result.data.createRequestPaydoc;
    }
    return false;
  }

  @Action({ rawError: true })
  public async uploadFile(data: {
    file: any;
    employeeId: string;
    category: string;
    period: Date;
    _id?: string;
  }): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Paydoc(
          $file: Upload!
          $employeeId: String
          $category: String!
          $period: DateTime!
          $_id: String
        ) {
          uploadPaydoc(
            file: $file
            employeeId: $employeeId
            category: $category
            period: $period
            _id: $_id
          )
        }
      `,
      variables: {
        file: data.file,
        employeeId: data.employeeId,
        category: data.category,
        period: data.period,
        _id: data._id
      },
      context: {
        uri: host,
        hasUpload: true,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.uploadPaydoc) {
      return result.data.uploadPaydoc;
    }
    return false;
  }

  @Action({ rawError: true })
  public async downloadPaydoc(_id: string): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Paydoc($_id: String!) {
          downloadPaydoc(_id: $_id)
        }
      `,
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.downloadPaydoc) {
      window.open(result.data.downloadPaydoc, '_blank');
    }
  }

  @Action({ rawError: true })
  public async getPaydocsFromRemote(_id = ''): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Paydoc($_id: String!) {
          paydocs(_id: $_id) {
            fullName
            category
            creationDate
            fileId
            period
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });

    if (result && result.data && result.data.paydocs) {
      this.context.commit('setPaydocs', result.data.paydocs);
    }
  }

  @Action({ rawError: true })
  public async getMyPaydocsFromRemote(_id = ''): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Paydoc {
          getMyPaydocs {
            fullName
            category
            creationDate
            fileId
            delivred
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });

    if (result && result.data && result.data.getMyPaydocs) {
      this.context.commit('setPaydocs', result.data.getMyPaydocs);
    }
  }
}
