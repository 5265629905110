




















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const login = namespace('login');

const application = namespace('application');

@Component({
  name: 'ForgotenPassword'
})
export default class ForgotenPassword extends Vue {
  @application.State
  public logoPath!: string;

  public valid = false;

  public loading = false;

  public userName = '';

  public isMailSent = false;

  public userNameRules = [
    (v: string) =>
      !v ||
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
      'E-mail must be valid'
  ];

  created() {
    this.setIsNavigation(false);
  }

  private async onContinue() {
    this.loading = true;
    const isResetPassword = await this.resetPassword(this.userName);

    if (isResetPassword) {
      this.isMailSent = true;
    }
    this.loading = false;
  }

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @login.Action
  private resetPassword!: (userName: string) => Promise<boolean>;
}
