import { VuexModule, Module, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Application extends VuexModule {
  public isNavigation = false;
  public isMenuHome = false;
  public isDarkMode = false;
  public logoPath = process.env.VUE_APP_LOGO_PATH;

  public mode = '';

  public title = '';

  @Mutation
  setMode(mode: string) {
    this.mode = mode;
  }

  @Mutation
  setTitle(title: string) {
    this.title = title;
  }

  @Mutation
  setIsNavigation(isNavigation: boolean) {
    this.isNavigation = isNavigation;
  }

  @Mutation
  setIsMenuHome(isMenuHome: boolean) {
    this.isMenuHome = isMenuHome;
  }

  @Mutation
  setIsDarkMode(isDarkMode: boolean) {
    this.isDarkMode = isDarkMode;
  }
}
