












import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const application = namespace('application');

@Component({
  name: 'Admin',
  components: { Header }
})
export default class Admin extends Vue {
  private created(): void {
    this.setIsNavigation(true);
  }

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;
}
