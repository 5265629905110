



































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AddProject from '../components/add-project.vue';

const application = namespace('application');

@Component({
  name: 'Projects',
  components: { AddProject }
})
export default class ProjectsManager extends Vue {
  public drawer = false;
  public search = '';
  public headers = [
    {
      text: 'Fullname',
      align: 'start',
      value: 'employee'
    },
    {
      text: 'Type',
      align: 'start',
      value: 'type'
    },
    {
      text: 'Creation Date',
      align: 'start',
      value: 'creationDate'
    }
  ];

  public projects = [];

  private async created(): Promise<void> {
    this.setIsNavigation(true);
    this.setTitle('Project(s)');
  }

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;
}
