

























import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '../types/user.type';

@Component({
  name: 'Managers',
  components: { Header }
})
export default class Managers extends Vue {
  @Prop({ default: () => [] }) managers!: User[];
  @Prop({ default: false }) manage!: boolean;
  @Prop({ default: false }) del!: boolean;

  public onlyDataTable = true;

  @Prop({
    default: () => [
      {
        text: 'Fullname',
        value: 'firstName'
      },
      {
        text: 'Role',
        value: 'role'
      },
      { text: '', value: 'actions' }
    ]
  })
  public headers!: [];

  public onManager(manager: User): void {
    this.$emit('manager', manager);
  }

  public onDelete(manager: User): void {
    this.$emit('delete', manager);
  }
}
