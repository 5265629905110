














































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PayrollItem from '../types/payrollItem.type';
import AddPayroll from '../components/add-payroll.vue';

const payroll = namespace('payroll');

@Component({
  name: 'Payroll',
  components: { Header, AddPayroll }
})
export default class Payroll extends Vue {
  public activeTab = 0;
  public search = '';
  public searchFilter = '*';
  public expanded = [];
  public singleExpand = false;

  public headers = [
    { text: 'Fullname', value: 'fullName' },
    { text: 'Type', value: 'type' },
    { text: 'Period', value: 'period' },
    { text: '', value: 'actions' }
  ];

  @payroll.State
  public payroll!: PayrollItem[];

  private async created(): Promise<void> {
    await this.getPayrollFromRemote();
  }

  public searchByName(): void {
    this.searchFilter = '*';
  }

  public onAll(): void {
    this.activeTab = 0;
  }

  public onPublished(): void {
    this.activeTab = 1;
  }

  public onPaid(): void {
    this.activeTab = 3;
  }

  public filterPayroll(value: unknown, search: string, item: PayrollItem) {
    if (this.activeTab === 0) {
      if (this.search.length) {
        return (
          value != null &&
          this.search != null &&
          typeof value === 'string' &&
          value
            .toString()
            .toLocaleUpperCase()
            .indexOf(this.search.toLocaleUpperCase()) !== -1
        );
      }
      return true;
    } else if (this.activeTab === 1) {
      if (this.search.length) {
        return (
          value != null &&
          this.search != null &&
          typeof value === 'string' &&
          value
            .toString()
            .toLocaleUpperCase()
            .indexOf(this.search.toLocaleUpperCase()) !== -1 &&
          item.status === 'published'
        );
      }
      return item.status === 'published';
    } else if (this.activeTab === 2) {
      if (this.search.length) {
        return (
          value != null &&
          this.search != null &&
          typeof value === 'string' &&
          value
            .toString()
            .toLocaleUpperCase()
            .indexOf(this.search.toLocaleUpperCase()) !== -1 &&
          item.status === 'paid'
        );
      }
      return item.status === 'paid';
    }
  }

  @payroll.Action
  private getPayrollFromRemote!: () => void;
}
