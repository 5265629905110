
































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Notification'
})
export default class Notification extends Vue {
  @Prop({ type: String, default: '' })
  public title!: string;

  @Prop({ type: String, default: '' })
  public icon!: string;

  @Prop({ type: String, default: '' })
  public message!: string;
}
