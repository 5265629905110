import apolloClient from '@/plugins/apollo';
import router from '@/router';
import gql from 'graphql-tag';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Register as RegisterType } from '../types/register.type';

@Module({ namespaced: true })
export default class Register extends VuexModule {
  public newUser: RegisterType = {
    firstName: '',
    lastName: '',
    mail: '',
    phone: '',
    password: ''
  };

  @Mutation
  public setNewUser(newUser: RegisterType) {
    this.newUser = newUser;
  }

  @Action
  public async registerOnRemote() {
    const host = process.env.VUE_APP_API_AUTH;
    const newUser = { ...this.newUser, userName: this.newUser.mail };
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Onbording($registerUserInput: RegisterUserInput!) {
          registerUser(registerUserInput: $registerUserInput) {
            userName
            success
          }
        }
      `,
      variables: { registerUserInput: newUser },
      context: {
        uri: host
      }
    });

    if (
      result &&
      result.data &&
      result.data.registerUser &&
      result.data.registerUser.success
    ) {
      router.push('/waiting-room');
    }
  }
}
