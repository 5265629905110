











































import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
import SelectCategory from './select-category.vue';
import SelectPeriod from './select-period.vue';
import { namespace } from 'vuex-class';
import { RequestPaydoc } from '../types/request-paydoc.type';

const paydocs = namespace('paydocs');

@Component({
  name: 'RequestDocument',
  components: { Header, Modal, SelectCategory, SelectPeriod }
})
export default class RequestDocument extends Vue {
  public showNotification = false;
  public fullName = '';
  public category = '';
  public period: Date | string = '';
  public loading = false;

  public closeDialog(): void {
    this.showNotification = false;
    this.loading = false;
  }

  public async save(): Promise<void> {
    const { category, fullName, period } = this;
    if (period && period !== '') {
      this.loading = true;
      const result = await this.createRequestPaydoc({
        category,
        fullName,
        period: period as Date
      });
      this.loading = false;
      if (result) {
        this.success(true);
      }
    } else if (category === 'Other') {
      this.loading = true;
      const result = await this.createRequestPaydoc({
        category,
        fullName
      });
      this.loading = false;
      if (result) {
        this.success(true);
      }
    }
  }

  public async success(args: boolean): Promise<void> {
    this.showNotification = true;
    await this.getMyPaydocsFromRemote();
    await this.getMyPayslipsFromRemote();
    this.$emit('success', args);
  }

  public setPeriod(period: Date): void {
    this.period = period;
  }
  public setCategory(category: string): void {
    this.category = category;
    if (category === 'Payslip') this.fullName = 'Payslip';
  }

  @paydocs.Action
  createRequestPaydoc!: (requestPaydocInput: RequestPaydoc) => Promise<boolean>;

  @paydocs.Action
  private getMyPaydocsFromRemote!: () => Promise<void>;

  @paydocs.Action
  private getMyPayslipsFromRemote!: () => Promise<void>;
}
