






























import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateEmployee from './update-employee.vue';
import { Employee } from '../types/employee.type';

@Component({
  name: 'UpdateEmployeeFirstNameAndLastName',
  components: { UpdateEmployee }
})
export default class UpdateEmployeeFirstNameAndLastName extends Vue {
  @Prop() employee!: Employee;

  @Prop({ default: false }) showNotification!: boolean;

  public changeAvatar(avatar: File): void {
    return;
  }
}
