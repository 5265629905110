





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Team } from '../../types/team.type';

const teams = namespace('teams');

@Component({
  name: 'SelectTeamNotUser'
})
export default class SelectTeamNotUser extends Vue {
  @teams.State
  public teamsNotUser!: Team[];

  @Prop() userId!: string;

  @Prop({ default: 'Team' }) label!: string;

  private created() {
    this.getTeamsNotUserFromRemote(this.userId);
  }

  private updated() {
    this.getTeamsNotUserFromRemote(this.userId);
  }

  @teams.Action
  getTeamsNotUserFromRemote!: (userId: string) => void;
}
