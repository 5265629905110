










import { User } from '@/modules/settings/types/user.type';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const application = namespace('application');

@Component({
  name: 'MenuHome'
})
export default class MenuHome extends Vue {
  @application.State
  public mode!: string;

  @Prop() isMenu!: boolean;

  @Prop() profile!: User;

  @Prop() items!: [];

  public showMenu = false;

  public itemsAdmin = [
    {
      action: 'mdi-cog',
      title: 'Settings',
      path: '/manager/settings',
      items: [],
      enable: true
    }
  ];

  private onButton(path: string) {
    this.$router.push(path);
  }

  get menu() {
    const m = [
      {
        action: 'mdi-view-dashboard',
        title: 'Overview',
        noAction: false,
        path: '/manager/overview',
        items: [],
        enable: this.profile?.role?.isManager || false,
        manager: true
      },
      {
        action: 'mdi-account-group',
        title: 'People',
        path: '/employees',
        items: [],
        enable: this.profile?.role?.isPeople || false,
        manager: true
      },
      {
        action: 'mdi-file-document-multiple-outline',
        title: 'Paydoc(s)',
        path: '/manager/paydocs',
        items: [],
        enable: this.profile?.role?.isPaydocsManager || false,
        manager: true
      },
      {
        action: 'mdi-calendar-multiselect',
        title: 'Time Off',
        path: '/manager/timeOff',
        items: [],
        enable: this.profile?.role?.isTimeOffManager || false,
        manager: true
      },
      {
        action: 'mdi-frequently-asked-questions',
        title: 'Request(s)',
        path: '/manager/requests',
        items: [],
        enable: this.profile?.role?.isRequestsManager || false,
        manager: true
      },
      {
        action: 'mdi-cog',
        title: 'Settings',
        path: '/manager/settings',
        items: [],
        enable: this.profile?.role?.isSettings || false,
        manager: true
      },
      {
        action: 'mdi-view-dashboard',
        title: 'Overview',
        noAction: false,
        path: '/overview',
        items: [],
        enable: true,
        manager: false
      },
      {
        action: 'mdi-information',
        title: 'My Info',
        path: '/myInfo',
        items: [],
        enable: false,
        manager: false
      },
      {
        action: 'mdi-file-document-multiple-outline',
        title: 'Paydoc(s)',
        path: '/paydocs',
        items: [],
        enable: this.profile?.role?.isPaydocs || false,
        manager: false
      },
      {
        action: 'mdi-calendar-multiselect',
        title: 'Timeoff',
        path: '/timeOff',
        items: [],
        enable: this.profile?.role?.isTimeOff || false,
        manager: false
      },
      {
        action: 'mdi-frequently-asked-questions',
        title: 'Request(s)',
        path: '/requests',
        items: [],
        enable: this.profile?.role?.isRequests || false,
        manager: false
      }
    ];
    return m;
  }

  public onLogin() {
    this.$router.push('/login');
  }
}
