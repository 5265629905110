






















import Header from '@/components/header.vue';
import { Employee } from '../types/employee.type';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '../types/user.type';

@Component({
  name: 'Members',
  components: { Header }
})
export default class Members extends Vue {
  @Prop({ default: () => [] }) members!: Employee[];
  @Prop({ default: false }) manage!: boolean;
  @Prop({ default: false }) del!: boolean;
  public onlyDataTable = true;

  @Prop({
    default: () => [
      {
        text: 'Fullname',
        value: 'firstName'
      },
      { text: '', value: 'actions' }
    ]
  })
  public headers!: [];

  public onMember(member: User): void {
    this.$emit('member', member);
  }

  public onDelete(manager: User): void {
    this.$emit('delete', manager);
  }
}
