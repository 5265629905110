import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Auth extends VuexModule {
  public token = '';

  public host = '';

  public isConnected = false;

  @Mutation
  public setIsConnected(isConnected: boolean) {
    this.isConnected = isConnected;
  }

  @Mutation
  public setHost(host: string) {
    this.host = host;
  }

  @Mutation
  public setToken(token: string): void {
    this.token = token;
  }

  @Action
  public saveHost(host: string) {
    this.context.commit('setHost', host);
  }

  @Action
  public saveToken(token: string) {
    this.context.commit('setToken', token);
  }

  @Action
  public saveIsConnected(isConnected: boolean) {
    this.context.commit('setIsConnected', isConnected);
  }
}
