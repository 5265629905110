




















import { Employee } from '@/modules/employees/types/employee.type';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Team } from '../../types/team.type';

const members = namespace('members');

@Component({
  name: 'SelectEmployeeNotTeam'
})
export default class SelectEmployeeNotTeam extends Vue {
  @members.State
  public employeesNotTeam!: Employee[];

  @Prop() team!: Team;

  @Prop({ default: 'Employee' }) label!: string;

  private async created() {
    await this.getEmployeesNotTeamFromRemote(this.team._id);
  }

  private async updated() {
    await this.getEmployeesNotTeamFromRemote(this.team._id);
  }

  public selectEmployee(employee: Employee) {
    this.$emit('employee', employee);
  }

  @members.Action
  getEmployeesNotTeamFromRemote!: (teamId: string) => void;
}
