import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import Request from '../types/request.type';
import CreateRequest from '../types/create-request.type';

@Module({ namespaced: true })
export default class Requests extends VuexModule {
  public newRequest: CreateRequest = {
    category: '',
    note: '',
    status: '',
    creationDate: new Date(),
    startDate: undefined,
    endDate: undefined,
    type: ''
  };

  public requests: Request[] = [];

  public requestsCount = 0;

  @Mutation
  clearNewRequest() {
    this.newRequest = {
      category: '',
      note: '',
      status: '',
      creationDate: new Date(),
      startDate: undefined,
      endDate: undefined,
      type: ''
    };
  }

  @Mutation
  setNewRequest(newRequest: CreateRequest) {
    this.newRequest = newRequest;
  }

  @Mutation
  setRequests(requests: Request[]) {
    this.requests = requests;
  }

  @Mutation
  setRequestsCount(requestsCount: number) {
    this.requestsCount = requestsCount;
  }

  @Mutation
  setCategory(category: string) {
    this.newRequest = { ...this.newRequest, category };
  }

  @Mutation
  setType(type: string) {
    this.newRequest = { ...this.newRequest, type };
  }

  @Action
  public async createRequest(): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const { newRequest } = this;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Request($createRequestInput: CreateRequestInput!) {
          createRequest(createRequestInput: $createRequestInput) {
            processed
          }
        }
      `,
      variables: { createRequestInput: newRequest },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.createRequest) {
      this.context.commit('clearNewRequest');
      this.context.dispatch('getMyRequestsFromRemote');
      return true;
    }
    return false;
  }

  @Action
  public async getRequestsFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Request {
          requests {
            _id
            category
            note
            status
            creationDate
            startDate
            endDate
            employeeId
            employee {
              firstName
              lastName
            }
            fullName
            processed
            type
            numberOfDays
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.requests) {
      this.context.commit('setRequests', result.data.requests);
    }
  }

  @Action
  public async getRequestsForMeCount() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Request {
          getRequestsForMeCount
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getRequestsForMeCount) {
      this.context.commit(
        'setRequestsCount',
        result.data.getRequestsForMeCount
      );
    }
  }

  @Action
  public async getMyRequestsFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Request {
          myRequests {
            _id
            category
            note
            status
            creationDate
            startDate
            endDate
            employeeId
            employee {
              firstName
              lastName
            }
            fullName
            processed
            type
            numberOfDays
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.myRequests) {
      this.context.commit('setRequests', result.data.myRequests);
    }
  }

  @Action
  public async getRequestsForMeFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Request {
          getRequestsForMe {
            _id
            category
            note
            status
            creationDate
            startDate
            endDate
            review
            reviewOwnerId
            reviewOwner {
              firstName
              lastName
            }
            employeeId
            employee {
              firstName
              lastName
            }
            fullName
            processed
            type
            numberOfDays
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getRequestsForMe) {
      this.context.commit('setRequests', result.data.getRequestsForMe);
    }
  }

  @Action
  public async updateRequest(request: Request) {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Request($updateRequestInput: UpdateRequestInput!) {
          updateRequest(updateRequestInput: $updateRequestInput) {
            category
            note
            status
            creationDate
            startDate
            endDate
            employeeId
            processed
            review
          }
        }
      `,
      variables: { updateRequestInput: request },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    return result;
  }

  @Action
  public async approveRequest(approveRequestInput: {
    _id: string;
    review: string;
  }): Promise<Request | null> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Request($approveRequestInput: ApproveRequestInput!) {
          approveRequest(approveRequestInput: $approveRequestInput)
        }
      `,
      variables: { approveRequestInput },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });

    if (result && result.data && result.data.approveRequest) {
      this.context.dispatch('getRequestsForMeFromRemote');
      return result.data.approveRequest;
    }
    return null;
  }

  @Action
  public async rejectRequest(rejectRequestInput: {
    _id: string;
    review: string;
  }): Promise<boolean | null> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Request($rejectRequestInput: RejectRequestInput!) {
          rejectRequest(rejectRequestInput: $rejectRequestInput)
        }
      `,
      variables: { rejectRequestInput },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });

    if (result && result.data && result.data.rejectRequest) {
      this.context.dispatch('getRequestsForMeFromRemote');
      return result.data.rejectRequest;
    }
    return null;
  }

  @Action({ rawError: true })
  public async removeRequestFromRemote(_id: string): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Request($_id: String!) {
          removeRequest(_id: $_id)
        }
      `,
      variables: { _id },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.removeRequest) {
      this.context.dispatch('getMyRequestsFromRemote');
    }
  }
}
