import auth from '@/guards/auth.guard';
import { RouteConfig } from 'vue-router';
import Settings from './../views/settings.vue';
import Users from './../components/users.vue';
import Roles from './../components/roles.vue';
import Teams from './../components/teams.vue';
import Application from './../components/application.vue';

const settingsRoutes: Array<RouteConfig> = [
  {
    path: '/manager/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/manager/settings/users',
    name: 'Users',
    component: Users,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/manager/settings/roles',
    name: 'Roles',
    component: Roles,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/manager/settings/teams',
    name: 'Teams',
    component: Teams,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/manager/settings/application',
    name: 'Application',
    component: Application,
    meta: {
      middleware: [auth]
    }
  }
];

export default settingsRoutes;
