











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Value extends Vue {
  @Prop({ default: 'empty', type: String }) private text!: string;

  @Prop({ default: '', type: String }) private color!: string;

  @Prop({ default: false, type: Boolean }) private isDisable!: boolean;
}
