













import { Employee } from '@/modules/settings/types/employee.type';
import { Component, Prop, Vue } from 'vue-property-decorator';
import EmployeeListItem from './employee-list-item.vue';

@Component({
  name: 'EmployeeList',
  components: {
    EmployeeListItem
  }
})
export default class EmployeeList extends Vue {
  @Prop() employees!: Employee[];

  @Prop({ default: false }) active!: boolean;

  public onEmployee(employee: Employee) {
    this.$emit('employee', employee);
  }
}
