import auth from '@/guards/auth.guard';
import { RouteConfig } from 'vue-router';
import Admin from './../views/admin.vue';

const adminRoutes: Array<RouteConfig> = [
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      middleware: [auth]
    }
  }
];

export default adminRoutes;
