import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import router from '@/router';
import { Employee } from '../types/employee.type';
import { CreateEmployee } from '../types/create-employee.type';
import imageCompression from 'browser-image-compression';

@Module({ namespaced: true })
export default class Employees extends VuexModule {
  public newEmployee: CreateEmployee = {
    nId: '',
    ssId: '',
    cId: '',
    firstName: '',
    lastName: '',
    fullName: '',
    mail: '',
    phone: '',
    contractType: '',
    baseSalary: 0,
    timeOffBalance: 0,
    team: '',
    teamId: '',
    jobTitle: '',
    terminated: false,
    familySituation: ''
  };

  public employee: Employee = {
    _id: '',
    nId: '',
    ssId: '',
    cId: '',
    firstName: '',
    lastName: '',
    fullName: '',
    mail: '',
    phone: '',
    birthdate: new Date(),
    dateOfHire: new Date(),
    contractType: '',
    baseSalary: 0,
    timeOffBalance: 0,
    team: { _id: '', fullName: '' },
    teamId: '',
    jobTitle: '',
    terminated: false,
    familySituation: '',
    nationality: ''
  };

  public employees: Employee[] = [];

  @Mutation
  clearNewEmployee(): void {
    this.newEmployee = {
      nId: '',
      ssId: '',
      cId: '',
      firstName: '',
      lastName: '',
      fullName: '',
      mail: '',
      phone: '',
      contractType: '',
      baseSalary: 0,
      timeOffBalance: 0,
      team: '',
      teamId: '',
      jobTitle: '',
      terminated: false,
      familySituation: ''
    };
  }

  @Mutation
  setEmployee(employee: Employee): void {
    this.employee = employee;
  }

  @Mutation
  setEmployees(employees: Employee[]): void {
    this.employees = employees;
  }

  @Mutation
  setNationality(nationality: string): void {
    this.newEmployee = { ...this.newEmployee, nationality };
  }

  @Mutation
  setTeamId(teamId: string): void {
    this.newEmployee = { ...this.newEmployee, teamId };
  }

  @Action
  public async getEmployeeFromRemote(_id: string): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Employee($_id: String!) {
          employee(_id: $_id) {
            _id
            nId
            ssId
            cId
            firstName
            lastName
            fullName
            mail
            phone
            birthdate
            nationality
            dateOfHire
            contractType
            baseSalary
            timeOffBalance
            team {
              _id
              fullName
            }
            teamId
            jobTitle
            terminated
            familySituation
            avatar
          }
        }
      `,
      variables: { _id },
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.employee) {
      this.context.commit('setEmployee', result.data.employee);
    }
  }

  @Action
  public async getMyEmployeesFromRemote(): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Employee {
          myEmployees {
            _id
            nId
            ssId
            cId
            firstName
            lastName
            fullName
            mail
            phone
            birthdate
            nationality
            dateOfHire
            contractType
            baseSalary
            timeOffBalance
            team {
              _id
              fullName
            }
            teamId
            jobTitle
            terminated
            familySituation
            avatar
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.myEmployees) {
      this.context.commit('setEmployees', result.data.myEmployees);
    }
  }

  @Action
  public async getEmployeesFromRemote(): Promise<void> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Employee {
          employees {
            _id
            nId
            ssId
            cId
            firstName
            lastName
            fullName
            mail
            phone
            birthdate
            nationality
            dateOfHire
            contractType
            baseSalary
            timeOffBalance
            team {
              _id
              fullName
            }
            teamId
            jobTitle
            terminated
            familySituation
            avatar
          }
        }
      `,
      fetchPolicy: 'network-only',
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.employees) {
      this.context.commit('setEmployees', result.data.employees);
    }
  }

  @Action
  public async createEmployee(): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const { newEmployee } = this;
    const result: any = await apolloClient.mutate({
      mutation: gql`
        mutation Employee($createEmployeeInput: CreateEmployeeInput!) {
          createEmployee(createEmployeeInput: $createEmployeeInput) {
            lastName
          }
        }
      `,
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      },
      variables: { createEmployeeInput: newEmployee }
    });
    if (result && result.data && result.data.createEmployee) {
      this.context.dispatch('getMyEmployeesFromRemote');
      return true;
    }
    return false;
  }

  @Action
  public async updateEmployee(employee: Employee): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result: any = await apolloClient.mutate({
      mutation: gql`
        mutation Employee($updateEmployeeInput: UpdateEmployeeInput!) {
          updateEmployee(updateEmployeeInput: $updateEmployeeInput)
        }
      `,
      variables: { updateEmployeeInput: employee },
      context: {
        uri: host,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.updateEmployee) {
      this.context.dispatch('getMyEmployeesFromRemote');
      return true;
    }
    return false;
  }

  @Action
  public async updateAvatar(updateAvatarInput: {
    avatar: File;
    _id: string;
  }): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const { profile } = this.context?.rootState?.profile;
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
      useWebWorker: true
    };

    const compressedAvatar = await imageCompression(
      updateAvatarInput.avatar,
      options
    );

    const reader = new FileReader();

    reader.onload = async e => {
      const updateEmployeeInput = {
        _id: updateAvatarInput._id,
        avatar: e.target?.result
      };
      const result = await apolloClient.mutate({
        mutation: gql`
          mutation Employee($updateEmployeeInput: UpdateEmployeeInput!) {
            updateEmployee(updateEmployeeInput: $updateEmployeeInput)
          }
        `,
        variables: { updateEmployeeInput },
        context: {
          uri: host,
          hasUpload: true,
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      });
      if (result && result.data && result.data.updateEmployee) {
        this.context.dispatch('getEmployeeFromRemote', this.employee._id);
        this.context.dispatch('getMyEmployeesFromRemote');
        if (this.employee._id === profile?.employeeId) {
          this.context.dispatch('profile/getMyProfile', '', { root: true });
        }
      }
      return false;
    };
    reader.onerror = error => {
      console.log('Error: ', error);
      return false;
    };
    await reader.readAsDataURL(compressedAvatar);
    return true;
  }
}
