















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User } from '../../types/user.type';
import UpdateUser from './update-user.vue';
import SelectRole from '../role/select-role.vue';
import { Role } from '../../types/role.type';

const users = namespace('users');

@Component({
  name: 'UpdateUserRole',
  components: { UpdateUser, SelectRole }
})
export default class UpdateUserRole extends Vue {
  @Prop() user!: User;

  @Prop({ default: false }) showNotification!: boolean;

  public selectRole(role: Role): void {
    this.setUser({ ...this.user, roleId: role._id });
  }

  @users.Mutation
  setUser!: (user: User) => void;
}
