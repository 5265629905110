






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Badge extends Vue {
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) title!: string;
}
