






















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Role } from '../../types/role.type';

const roles = namespace('roles');

@Component({
  name: 'SelectRole'
})
export default class SelectRole extends Vue {
  @roles.State
  public roles!: Role[];

  @Prop() role!: Role;

  private created() {
    this.getRolesFromRemote();
  }

  @roles.Action
  public getRolesFromRemote!: () => void;
}
