

















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';

const application = namespace('application');

@Component({
  name: 'AddPayrollSummariesReceiver',
  components: { Modal }
})
export default class AddPayrollSummariesReceiver extends Vue {
  public showNotification = false;

  public closeDialog(): void {
    return;
  }
  public save(): void {
    return;
  }
}
