
































import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Team } from '../../types/team.type';
import { User } from '../../types/user.type';

const managers = namespace('managers');

@Component({
  name: 'SelectMangerNotTeam'
})
export default class SelectMangerNotTeam extends Vue {
  @managers.State
  public managersNotTeam!: User[];

  @Prop() team!: Team;

  @Prop({ default: 'User' }) label!: string;

  private async created() {
    await this.getManagersNotTeamFromRemote(this.team._id);
  }

  @managers.Action
  getManagersNotTeamFromRemote!: (teamId: string) => Promise<void>;
}
