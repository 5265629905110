












import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UploadDocument from '@/components/upload-document.vue';
import { Employee } from '@/modules/employees/types/employee.type';

const paydocs = namespace('paydocs');

@Component({
  name: 'UploadPaydoc',
  components: { UploadDocument }
})
export default class UploadPaydoc extends Vue {
  @Prop() private employee!: Employee;

  @Prop() private category!: string;

  @Prop() private _id!: string;

  @Prop({ default: () => [] }) private paydoc!: [];

  @Prop() public disabled!: boolean;

  @Prop({ default: '' }) public period!: Date | undefined;

  public success = false;

  public async upload(file: any) {
    const { _id } = this;
    let newFile: {
      _id?: string;
      file: any;
      employeeId: string;
      category: string;
      period: Date;
    } = {
      file,
      employeeId: this.employee._id,
      category: this.category,
      period: this.period || new Date()
    };
    if (_id && this._id.length > 0) {
      newFile = { ...newFile, _id };
    }
    const result = await this.uploadFile(newFile);

    if (result) {
      this.success = true;
      this.$emit('success', true);
    }
  }

  @paydocs.Action
  public uploadFile!: (data: {
    _id?: string;
    file: any;
    employeeId: string;
    category: string;
    period: Date;
  }) => Promise<boolean>;
}
