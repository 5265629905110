























































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Users from './../components/users.vue';
import Roles from './../components/roles.vue';
import Teams from './../components/teams.vue';
import User from './../components/user/user.vue';
import Role from './../components/role/role.vue';
import Team from './../components/team/team.vue';
import NewUser from './../components/user/new-user.vue';
import NewRole from './../components/role/new-role.vue';
import NewTeam from './../components/team/new-team.vue';
import ApplicationSettings from '../components/application.vue';

const application = namespace('application');

@Component({
  name: 'Settings',
  components: {
    Header,
    Users,
    Roles,
    Teams,
    ApplicationSettings,
    User,
    Role,
    Team,
    NewUser,
    NewRole,
    NewTeam
  }
})
export default class Settings extends Vue {
  public drawer = false;

  private created(): void {
    this.setIsNavigation(true);
    this.setTitle('Settings');
  }

  public onUsers() {
    this.$router.push('/manager/settings/users');
  }

  public onApplication() {
    this.$router.push('/manager/settings/application');
  }

  public onRoles() {
    this.$router.push('/manager/settings/roles');
  }

  public onTeams() {
    this.$router.push('/manager/settings/teams');
  }

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;
}
