

















import Header from '@/components/header.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/modal.vue';
import { namespace } from 'vuex-class';

@Component({
  name: 'AddProject',
  components: {
    Header,
    Modal
  }
})
export default class AddProject extends Vue {
  public showNotification = false;

  public closeDialog() {
    this.showNotification = false;
  }

  public async save() {
    /*     if (result) { */
    this.showNotification = true;
    /*    } */
  }

  public onSuccess(args: boolean): void {
    this.showNotification = true;
    this.$emit('success', args);
  }
}
