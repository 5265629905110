



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import Value from '@/components/value.vue';
import { Role } from '../../types/role.type';

const roles = namespace('roles');

@Component({
  name: 'DeleteRole',
  components: { Modal, Value }
})
export default class DeleteRole extends Vue {
  @Prop() role!: Role;

  public showNotification = false;

  public async onDelete(_id: string) {
    const deleted = await this.deleteRoleFromRemote(_id);
    if (deleted) {
      this.showNotification = true;
    }
  }

  @roles.Action
  public deleteRoleFromRemote!: (_id: string) => Promise<boolean>;
}
