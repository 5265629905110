import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { apolloProvider } from './plugins/apollo';
import vuetify from './plugins/vuetify';
import filters from './plugins/filters';

Vue.config.productionTip = false;
Vue.use(filters);

new Vue({
  router,
  store,
  apolloProvider,
  vuetify,
  render: h => h(App)
}).$mount('#app');
