


























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const application = namespace('application');

@Component({ name: 'Products' })
export default class Products extends Vue {
  public items = [
    {
      icon: 'mdi-account-group',
      header: 'Employee(s)',
      img: '',
      description: ''
    },
    {
      icon: 'mdi-file-document-multiple-outline',
      header: 'Paydoc'
    },
    {
      icon: 'mdi-calendar-multiselect',
      header: 'Time off'
    },
    {
      icon: 'mdi-frequently-asked-questions',
      header: 'Requests'
    },
    {
      icon: 'mdi-folder-text-outline',
      header: 'Payroll'
    },
    {
      icon: 'mdi-calendar',
      header: 'Calendar'
    },
    {
      icon: 'mdi-receipt',
      header: 'Costs'
    },
    {
      icon: 'mdi-file-chart',
      header: 'Expense Reports'
    }
  ];
}
