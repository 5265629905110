



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const profile = namespace('profile');
const application = namespace('application');
const login = namespace('login');

@Component({
  name: 'Profile'
})
export default class Profile extends Vue {
  @profile.State
  public profile!: Profile;

  @profile.State
  public avatar!: string;

  @application.State
  public mode!: string;

  public onMyAccount(): void {
    this.$router.push('/myAccount');
  }

  public onModeManager(): void {
    this.setMode('manager');
    this.$router.push('/manager/overview');
  }

  public onModeEmployee(): void {
    this.setMode('employee');
    this.$router.push('/overview');
  }

  public onLogout() {
    this.logOut();
  }

  @application.Mutation
  setMode!: (mode: string) => void;

  @login.Action
  logOut!: () => void;
}
