








































































































































































import Header from '@/components/header.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Modal from '@/components/modal.vue';
import SelectTeam from '@/modules/settings/components/team/select-team.vue';
import { Team } from '@/modules/settings/types/team.type';
import SelectPeriod from './../components/select-period.vue';
import EmployeeList from '@/modules/employees/components/employee-list.vue';
import { Employee } from '@/modules/settings/types/employee.type';
import UploadPayroll from './upload-payroll.vue';
import UploadPaydoc from '../../paydocs/components/upload-paydoc.vue';
import UploadPayrollSummary from '../../paydocs/components/upload-payroll-summary.vue';

const employees = namespace('employees');

@Component({
  name: 'AddPayroll',
  components: {
    Header,
    Modal,
    SelectTeam,
    SelectPeriod,
    EmployeeList,
    UploadPayroll,
    UploadPaydoc,
    UploadPayrollSummary
  }
})
export default class AddPayroll extends Vue {
  @employees.State
  public employees!: Employee[];
  public period: Date | string = '';
  public showNotification = false;
  public search = '';
  public searchDisplay = '';
  public step = 1;
  public linkMode = '';
  public selectedTeam: Team = {
    _id: '',
    fullName: ''
  };
  public isList = 0; //0:List|1:Card
  public members: Employee[] = [];
  public headers = [
    {
      text: 'Fullname',
      value: 'firstName'
    },

    { text: 'Team', value: 'team.fullName' },
    { text: 'Job title', value: 'jobTitle' },
    { text: '', value: 'actions' }
  ];

  private async created(): Promise<void> {
    await this.getMyEmployeesFromRemote();
  }

  public setPeriod(period: Date) {
    this.period = period;
    this.step += 1;
  }

  public searchByName(args: string): void {
    this.search = args;
  }

  public onAuto(): void {
    this.linkMode = 'auto';
    this.step += 1;
  }

  public onManual(): void {
    this.linkMode = 'manual';
    this.step += 1;
  }

  public previous(): void {
    this.step -= 1;
  }

  public next(): void {
    this.step += 1;
  }

  public closeDialog(): void {
    this.showNotification = false;
  }

  public save(): void {
    this.showNotification = true;
  }

  public selectTeam(team: Team): void {
    this.selectedTeam = team;
    this.searchDisplay = '*';
  }

  public onEmployee(employee: Employee): void {
    this.members.push(employee);
  }

  public selectAll(): void {
    if (this.selectedTeam && this.selectedTeam.members) {
      this.members = this.members.map((employee, index) => {
        return { ...employee, active: true, index };
      });
    }
  }

  public unselectAll(): void {
    if (this.selectedTeam && this.selectedTeam.members) {
      this.members = this.members.map((employee, index) => {
        return { ...employee, active: false, index };
      });
    }
  }

  get displayEmployees(): Employee[] {
    return this.employees.filter(element => {
      if (
        this.selectedTeam &&
        this.selectedTeam._id &&
        this.selectedTeam._id !== ''
      )
        return element.teamId === this.selectedTeam._id;
      return true;
    });
  }

  public filterEmployee(value: unknown, search: string, item: Employee) {
    if (this.search.length) {
      if (
        this.selectedTeam &&
        this.selectedTeam._id &&
        this.selectedTeam._id !== ''
      )
        return (
          item.teamId === this.selectedTeam._id &&
          value != null &&
          this.search != null &&
          typeof value === 'string' &&
          value
            .toString()
            .toLocaleUpperCase()
            .indexOf(this.search.toLocaleUpperCase()) !== -1
        );
      return (
        value != null &&
        this.search != null &&
        typeof value === 'string' &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(this.search.toLocaleUpperCase()) !== -1
      );
    } else {
      if (
        this.selectedTeam &&
        this.selectedTeam._id &&
        this.selectedTeam._id !== ''
      )
        return item.teamId === this.selectedTeam._id;
      return true;
    }
  }

  @employees.Action
  getMyEmployeesFromRemote!: () => void;
}
