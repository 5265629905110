import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import apolloClient from '@/plugins/apollo';
import gql from 'graphql-tag';
import router from '@/router';
import PayrollItem from '../types/payrollItem.type';

@Module({ namespaced: true })
export default class Payroll extends VuexModule {
  public payroll: PayrollItem[] = [];

  @Mutation
  setPayroll(payroll: PayrollItem[]) {
    this.payroll = payroll;
  }

  @Action
  public async getPayrollFromRemote() {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.query({
      query: gql`
        query Paydoc {
          getPayroll {
            fullName
            type
            category
            period
            status
            creationDate
          }
        }
      `,
      context: {
        uri: host,
        hasUpload: true,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.getPayroll) {
      this.context.commit('setPayroll', result.data.getPayroll);
      return true;
    }
    return false;
    this.context.commit('setPayroll', result);
  }

  @Action({ rawError: true })
  async uploadPayroll(data: {
    file: any;
    employeeId: string;
    category: string;
  }): Promise<boolean> {
    const { token, host } = this.context?.rootState?.auth;
    const result = await apolloClient.mutate({
      mutation: gql`
        mutation Paydoc(
          $file: Upload!
          $employeeId: String!
          $category: String!
        ) {
          uploadPaydoc(
            file: $file
            employeeId: $employeeId
            category: $category
          )
        }
      `,
      variables: {
        file: data.file,
        employeeId: data.employeeId,
        category: data.category
      },
      context: {
        uri: host,
        hasUpload: true,
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    });
    if (result && result.data && result.data.uploadPaydoc) {
      this.context.dispatch('getPaydocsFromRemote', data.employeeId);
      return true;
    }
    return false;
  }
}
