













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'UploadDocument'
})
export default class UploadDocument extends Vue {
  @Prop() public disabled!: boolean;

  @Prop() public file!: unknown;

  public upload(event: any) {
    if (event !== []) {
      this.$emit('file', event);
    }
  }
}
