

























































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue';
import { namespace } from 'vuex-class';
import { Payslip } from '../types/payslip.type';
import { User } from '@/modules/settings/types/user.type';
import RequestDocuemt from './../components/request-document.vue';

const paydocs = namespace('paydocs');
const application = namespace('application');
const profile = namespace('profile');

@Component({
  name: 'Paydocs',
  components: { Header, RequestDocuemt }
})
export default class Paydocs extends Vue {
  @profile.State
  public profile!: User;
  public search = '';
  public activeTab = 0;
  public headers = [
    {
      text: 'Fullname',
      align: 'start',
      sortable: true,
      value: 'fullName'
    },
    {
      text: 'Period',
      value: 'period'
    },
    {
      text: 'Category',
      align: 'start',
      sortable: true,
      value: 'category'
    },
    {
      text: 'Date',
      value: 'creationDate'
    },
    {
      text: 'Status',
      value: 'delivred',
      width: '150'
    },
    /*   { text: 'Check Amount', value: 'checkAmount' }, */
    { text: 'Action', value: 'actions', sortable: false }
  ];

  @paydocs.State
  public payslips!: Payslip[];

  @paydocs.State
  public paydocs!: [];

  @paydocs.State
  public employeeId!: string;

  private async created(): Promise<void> {
    this.setIsNavigation(true);
    this.setTitle('Pay');
    await this.getMyPayslipsFromRemote();
    await this.getMyPaydocsFromRemote();
  }

  public async showItem(fileId: string): Promise<void> {
    await this.downloadPaydoc(fileId);
  }

  @paydocs.Action
  private downloadPaydoc!: (fileId: string) => void;

  @paydocs.Action
  private getMyPayslipsFromRemote!: () => Promise<void>;

  @paydocs.Action
  private getMyPaydocsFromRemote!: () => Promise<void>;

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;
}
