






























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Calendar from '@/components/calendar.vue';
import Header from '@/components/header.vue';
import TimelineItem from './../components/TimelineItem.vue';
import ToDoListItem from './../components/to-do-list-item.vue';
import EmployeesOnHoliday from './../components/employees-on-holiday.vue';
import { namespace } from 'vuex-class';
import HolidayItem from '../types/holiday.type';
import Upcoming from '../components/Upcoming.vue';
import Celebrations from '../components/celebrations.vue';
import ToDoItem from '../types/ToDoItem.type';
import Profile from '@/components/profile.vue';
import { User } from '@/modules/settings/types/user.type';
import MyLastPayslip from '../components/my-last-payslip.vue';
import { Event } from '@/components/calendar.vue';
import TimeOff from '@/modules/timeOff/types/time-off.type';
import dtFormat from '@/plugins/filters/dateFilter';

const overview = namespace('overview');
const profile = namespace('profile');
const application = namespace('application');
const timeOff = namespace('timeOff');

@Component({
  components: {
    Calendar,
    Header,
    TimelineItem,
    ToDoListItem,
    EmployeesOnHoliday,
    Upcoming,
    Celebrations,
    Profile,
    MyLastPayslip
  }
})
export default class Overview extends Vue {
  @overview.State
  private toDo!: ToDoItem[];

  @overview.State
  private holiday!: HolidayItem[];

  @overview.State
  private timeline!: TimelineItem[];

  @profile.State
  public profile!: User;

  @timeOff.State
  public timeOff!: TimeOff[];

  public events: Event[] = [];

  get getEvents() {
    return this.timeOff
      .filter(item => item.type !== 'Paid Leave Rights')
      .map(item => {
        return {
          name: item.type,
          start: dtFormat(item.startDate?.toString() || '', 'YYYY-MM-DD'),
          end: dtFormat(item.endDate?.toString() || '', 'YYYY-MM-DD'),
          timed: false,
          color: item.status === 'approved' ? 'success' : 'primary'
        };
      });
  }

  private async created() {
    await this.getTimeLineFromRemote();
    this.setIsNavigation(true);
    this.setTitle(`Good afternoon, ${this.profile.firstName}`);
    await this.getMyTimeOffFromRemote();
  }

  @overview.Action
  private getTimeLineFromRemote!: () => void;

  @timeOff.Action
  private getMyTimeOffFromRemote!: () => Promise<void>;

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;
}
