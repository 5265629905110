





















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({ name: 'UpdateAvatar' })
export default class UpdateAvatar extends Vue {
  @Prop({ default: false }) icon!: boolean;

  public $refs!: {
    uploader: HTMLFormElement;
  };
  public isSelecting = false;
  public srcImage: any = '';

  public onFileChanged(file: any): void {
    this.$emit('save', file.target.files[0]);
  }

  public onButtonClick(): void {
    this.isSelecting = true;
    window.addEventListener(
      'focus',
      () => {
        this.isSelecting = false;
      },
      { once: true }
    );

    this.$refs.uploader.click();
  }
}
