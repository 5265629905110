

















































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Header from '@/components/header.vue';
import MyInfoItem from '../components/my-info-item.vue';
import { namespace } from 'vuex-class';
import Label from '@/components/label.vue';
import Value from '@/components/value.vue';
import UpdateFirstNameAndLastName from '../components/update-firstname-and-lastname.vue';
import UpdateBirthdate from '../components/update-birthdate.vue';
import UpdateMail from '../components/update-mail.vue';
import UpdatePhone from '../components/update-phone.vue';
import UpdateBank from '../components/update-bank.vue';
import { User } from '@/modules/settings/types/user.type';
import { UpdateMyInfoInput } from '../types/update-my-info.input';
import UpdateAvatar from '../../../components/update-avatar.vue';
import UpdatePassword from '../components/update-password.vue';
import UpdateMyPasswordInput from '../types/update-my-password.input.vue';

const overview = namespace('overview');
const application = namespace('application');
const profile = namespace('profile');
@Component({
  components: {
    Header,
    MyInfoItem,
    Label,
    Value,
    UpdateFirstNameAndLastName,
    UpdateBirthdate,
    UpdateMail,
    UpdatePhone,
    UpdateBank,
    UpdateAvatar,
    UpdatePassword
  }
})
export default class MyInfo extends Vue {
  @profile.State
  profile!: User;

  @profile.State
  avatar!: string;

  public showNotification = false;

  private created(): void {
    this.setIsNavigation(true);
    this.setTitle('My Account');
  }

  public reset(): void {
    this.showNotification = false;
  }

  public setBirthdate(birthdate: Date) {
    this.setProfile({ ...this.profile, birthdate });
  }

  public async updateAvatar(avatar: string): Promise<void> {
    const isUpdated = await this.updateMyAvatar(avatar);
  }

  public async updateMailNotification(): Promise<void> {
    const isUpdated = await this.updateMyInfo({
      mailNotification: this.profile.mailNotification
    });
    if (isUpdated) {
      this.showNotification = true;
      await this.getMyProfile();
    }
  }

  public async updateFullname(): Promise<void> {
    const isUpdated = await this.updateMyInfo({
      firstName: this.profile.firstName,
      lastName: this.profile.lastName
    });
    if (isUpdated) {
      this.showNotification = true;
      await this.getMyProfile();
    }
  }

  public async updateBirthdate(birthdate: Date): Promise<void> {
    const isUpdated = await this.updateMyInfo({
      birthdate: this.profile.birthdate
    });
    if (isUpdated) {
      this.showNotification = true;
      await this.getMyProfile();
    }
  }

  public async updateMail(): Promise<void> {
    const isUpdated = await this.updateMyInfo({
      mail: this.profile.mail
    });
    if (isUpdated) {
      this.showNotification = true;
      await this.getMyProfile();
    }
  }

  public async updatePhone(): Promise<void> {
    const isUpdated = await this.updateMyInfo({
      phone: this.profile.phone
    });
    if (isUpdated) {
      this.showNotification = true;
      await this.getMyProfile();
    }
  }

  public async updatePassword(
    updateMyPasswordInput: UpdateMyPasswordInput
  ): Promise<void> {
    const isUpdated = await this.updateMyPassword(updateMyPasswordInput);
    console.log('isUpdated', isUpdated);
    if (isUpdated) {
      this.showNotification = true;
    }
  }

  @profile.Mutation
  setProfile!: (profile: User) => void;

  @application.Mutation
  setIsNavigation!: (isNavigation: boolean) => void;

  @application.Mutation
  setTitle!: (title: string) => void;

  @profile.Action
  updateMyInfo!: (updateMyInfoInput: UpdateMyInfoInput) => Promise<boolean>;

  @profile.Action
  updateMyAvatar!: (avatar: string) => Promise<boolean>;

  @profile.Action
  getMyProfile!: () => Promise<void>;

  @profile.Action
  updateMyPassword!: (
    updateMyPasswordInput: UpdateMyPasswordInput
  ) => Promise<boolean>;
}
