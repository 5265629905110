







import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import UploadDocument from '@/components/upload-document.vue';

const paydocs = namespace('paydocs');

@Component({
  name: 'UploadPayrollSummary',
  components: { UploadDocument }
})
export default class UploadPayrollSummary extends Vue {
  @Prop() public period!: Date;

  @Prop() public payroll!: [];

  @Prop() public disabled!: boolean;

  public success = false;

  public async upload(file: any) {
    const fileToSave = {
      file,
      category: 'Payroll',
      type: 'Summary',
      period: this.period
    };

    console.log('fileToSave', fileToSave);
    const result = await this.uploadFile(fileToSave);
    if (result) {
      this.success = true;
      this.$emit('success', true);
    }
  }

  @paydocs.Action
  public uploadFile!: (data: {
    file: any;
    type: string;
    category: string;
    period: Date;
  }) => Promise<boolean>;
}
