






















import { Component, Prop, Vue } from 'vue-property-decorator';
import Update from './update.vue';
import { User } from '@/modules/settings/types/user.type';

@Component({
  name: 'UpdateMail',
  components: { Update }
})
export default class UpdateMail extends Vue {
  @Prop() user!: User;

  @Prop({ default: false }) showNotification!: boolean;
}
